<template>
    <div>
<!--        分类选择-->
        <div style="height: 40px;display: flex;margin-bottom: 10px;margin-left: 25px">
            <div style="margin-top: 5px;"><el-radio-button   @click="getAllPersonalPost">全部</el-radio-button></div>
            <div style="display: flex;margin-left: 10px">
                <el-radio-group v-model="postTypeId" v-for="(postType,index) in postTypes" :key="index">
                    <el-radio-button style="margin-right: 5px" :label="postType.postTypeId" @click="getPostListByType(postType.postTypeId)">{{postType.postTypeDetail}}</el-radio-button>
                </el-radio-group>
            </div>
        </div>
<!--        结果展示-->
        <div style="width: 80%;margin-left: 1%" class="main">
            <el-card shadow="hover" v-for="(message,index) in messages" :key="index"
                     style="margin-left: 5%;width:700px ">

                <div style="height:100px;margin-left: 0">
                    <div style="display:inline-block;margin-left: 0%;width: 80%">
                        <p class="message" style="font-weight:bold">{{message.postTitle}}</p>
                        {{message.postUserName}} &nbsp;&nbsp;{{message.postType}}<br/>
                        <p style="font-weight: lighter" class="message">
                            {{message.postFirstLine}}<br/>
                            {{message.postSecondLine}}
                        </p>
                        <p class="message">
                            浏览：
                            <i class="el-icon-view"></i>
                            {{message.browses}}
                            &nbsp;&nbsp;
                            收藏：
                            <i class="el-icon-star-off"></i>
                            {{message.postCollections}}
                            &nbsp;&nbsp;
                            点赞：
                            <i class="el-icon-coordinate"></i>
                            {{message.postLikes}}
                            &nbsp;&nbsp;
                            评论：
                            <i class="el-icon-coordinate"></i>
                            {{message.postComments}}
                            &nbsp;&nbsp;
                            {{message.postCreateTime}}
                        </p>
                    </div>
                            <!--修改和删除按钮-->
                        <div  style="width:18%;height: 100%;display:inline-block;">
                            <div style="display: inline-block;width: 50%;margin-top: 20%;margin-left: 24%">
                            <el-button type="primary" round style="height: 50%;width: 100%;display: inline-block" @click="changPost(message.postId)">
                                <el-icon :size="20">
                                    <Edit />
                                </el-icon>
                            </el-button>
                                <el-button type="danger" round style="height: 50%;width: 100%;display: inline-block;margin-left: 0;margin-top: 5px" @click="deletePost(message.postId)">
                                    <el-icon>
                                        <Delete />
                                    </el-icon>
                                </el-button>
                            <br><br>
                        </div>
                    </div>
                </div>
                <br>
            </el-card>
        </div>
        <br>
        <div class="footer" style="margin: 0 auto;width: 100%;">
            <div class="block">
                <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-size="pageSize"
                        layout="total, prev, pager, next, jumper"
                        :total=total>
                </el-pagination>
            </div>
        </div>
    </div>

</template>


<script>


    import {getAllPersonalPost,getPostTypes,getPostListByType} from "../../apis/post";

    export default {
        data() {
            return {
                total: 5,
                pageSize: 4,
                pageNum: 1,
                messages: [],
                postTypes:[],
                postTypeId: 1,
            }
        },
        methods: {
            async getAllPersonalPost(){
                this.postTypeId=-1
                const temp1 = await getAllPersonalPost(
                    {userId: window.localStorage.getItem('userId'),
                        pageSize:this.pageSize,
                        pageNum:this.pageNum
                    })
                this.messages=temp1.data.postList
                this.total=temp1.data.total
            },
            changPost(postId){
                this.$router.push({path:'/personalCenter/modificationPost',query:{postId:postId}})
            },
            async getPostListByType(postTypeId){
                const temp1 = await getPostListByType(
                    {userId: window.localStorage.getItem('userId'),
                        postTypeId:postTypeId,
                        pageSize:this.pageSize,
                        pageNum:this.pageNum
                    })
                this.messages=temp1.data.postList
                this.total=temp1.data.total
                console.log(this.messages)
            },
            async deletePost(postId){
                // const temp1 = await deletePostByPostId(
                //     {userId:window.localStorage.getItem('userId'),
                //         postId:this.postId,
                //     })
                console.log(postId)
                var l=2000
                if (l===2000){
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                }else {
                    this.$message.error('删除失败');
                }
            }
            ,
            async getPostTypes(){
               const temp=await getPostTypes();
                this.postTypes=temp.data.postType
                console.log(this.postTypes)
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.pageNum=val
                this.getAllPersonalPost()
                console.log(`当前页: ${val}`);
            }
        },
        mounted() {
            this.getPostTypes()
            this.getAllPersonalPost()
        }
    }
</script>

<style>

</style>