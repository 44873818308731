<template>
    <div>
        <!--        分类选择-->
        <div style="width:730px;min-height: 40px;display: flex;margin-bottom: 10px;margin-left: 25px;">
            <div style="display: flex;margin-left: 10px;flex-wrap: wrap">
              <div style="margin-right: 5px;margin-top: 5px"><el-radio-button   @click="getAllPersonalCouplet">全部</el-radio-button></div>
              <el-radio-group v-model="coupletTypeId" v-for="(coupletType,index) in coupletTypeList" :key="index">
                    <el-radio-button style="margin-right: 5px;margin-top: 5px" :label="coupletType.coupletTypeId" @click="getCoupletListByCoupletType(coupletType.coupletTypeId)">{{coupletType.coupletTypeDetail}}</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <!--        结果展示-->
        <div style="width: 80%;margin-left: 1%" class="main">
            <el-card shadow="hover" v-for="(message,index) in messages" :key="index"
                     style="margin-left: 5%;width:700px ">

                <div style="height:100px;margin-left: 0">
                    <div style="display:inline-block;margin-left: 0%;width: 80%">
                        <p class="message" style="font-weight:bold" @click="switchTo">{{message.coupletTitle}}</p>
                        {{message.coupletAuthorName}} &nbsp;&nbsp;{{message.coupletType}}<br/>
                        <p style="font-weight: lighter" class="message">
                            {{message.coupletFirstLine}}<br/>
                            {{message.coupletSecondLine}}
                        </p>
                        <p class="message">
                            浏览：
                            <i class="el-icon-view"></i>
                            {{message.coupletBrowses}}
                            &nbsp;&nbsp;
                            收藏：
                            <i class="el-icon-star-off"></i>
                            {{message.coupletCollections}}
                            &nbsp;&nbsp;
                            点赞：
                            <i class="el-icon-coordinate"></i>
                            {{message.coupletLikes}}
                            &nbsp;&nbsp;
                            评论：
                            <i class="el-icon-coordinate"></i>
                            {{message.coupletComments}}
                            &nbsp;&nbsp;
                            {{message.coupletCreateTime}}
                        </p>
                    </div>

                    <div  style="width:18%;height: 100%;display:inline-block;">
                        <div style="display: inline-block;width: 50%;margin-top: 20%;margin-left: 24%">
                            <el-button type="primary" round style="height: 50%;width: 100%;display: inline-block" @click="viewCouplets(message.coupletId)">
                                <el-icon :size="20">
                                    <Edit />
                                </el-icon>
                            </el-button>
                            <br><br>
                        </div>
                    </div>
                </div>
                <br>
            </el-card>
        </div>
        <br>
        <div class="footer" style="margin: 0 auto;width: 100%;">
            <div class="block">
                <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-size="pageSize"
                        layout="total, prev, pager, next, jumper"
                        :total=total>
                </el-pagination>
            </div>
        </div>
    </div>

</template>


<script>


   import {getCoupletType,getAllPersonalCouplet,getCoupletListByCoupletType} from "../../apis/couplet";

    export default {
        data() {
            return {
                total: 5,
                pageSize: 4,
                pageNum: 1,
                messages: [],
                coupletTypeList:[],
                coupletTypeId: 1,
            }
        },
        methods: {
            async getAllPersonalCouplet(){
                this.coupletTypeId=-1
                const temp1 = await getAllPersonalCouplet(
                    {coupletAuthorId: window.localStorage.getItem('userId'),
                        pageSize:this.pageSize,
                        pageNum:this.pageNum
                    })
                this.messages=temp1.data.coupletList
                this.total=temp1.data.total
            },
            async getCoupletListByCoupletType(coupletTypeId){
                const temp1 = await getCoupletListByCoupletType(
                    {userId: window.localStorage.getItem('userId'),
                        coupletTypeId:coupletTypeId,
                        pageSize:this.pageSize,
                        pageNum:this.pageNum
                    })
                this.messages=temp1.data.coupletList
                this.total=temp1.data.total
                console.log(this.messages)
            },
            async getCoupletType(){
                const temp=await getCoupletType();
                this.coupletTypeList=temp.data.coupletTypeList
                console.log(this.coupletTypeList)
            },
            viewCouplets(coupletId){
                this.$router.push({path:'/personalCenter/getCoupletDetailInfo',query:{coupletId:coupletId}})
            }
            ,
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.pageNum=val
                this.getAllPersonalCouplet()
                console.log(`当前页: ${val}`);
            }
        },
        mounted() {
            this.getCoupletType()
            this.getAllPersonalCouplet()
        }
    }
</script>

<style>

</style>
