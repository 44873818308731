<template>
  <div style="display:flex;flex-direction:column;width:100%">
    <span style="margin-left:20px;text-align:left;font-weight: bold;font-size: 16px;">对联频道</span>
    <el-divider style="margin-top:0px;" border-style="double" content-position="left"/>
  </div>
  <div id="content">
    <div class="content-outline">
      <div style="width:95%">
        <div style="width:100%;height:40px;display:flex;flex-direction:row;margin-top:-25px;">
          <el-divider style="width:5%"/>
          <el-link :underline="false" style="font-size: 15px;margin:0 5px">佳作展示</el-link>
          <el-divider style="max-width:82%"/>
        </div>
      </div>

      <div
          style="width:95%;height:100%;margin-top:-10px;display: flex;flex-direction:row;flex-wrap:wrap;">
        <div v-for="item in coupletList" :key="item"
             style="margin-left:10px;width:300px;height:120px;border:1px solid lightgray;">
          <div class="coupletTitle">
            <p class="coupletTitle_content">{{ item.coupletTitle }}</p>
          </div>

          <div style="width:100%;height:30px;display:flex;flex-direction:row;">
            <h1 style="width:32%;line-height:30px;height:30px;margin:0;font-size:14px;display:flex;flex-direction:row;justify-content:center">
              <p style="font-weight: bold">作者:</p>{{ item.coupletAuthorName }}
            </h1>
            <h1 style="width:23%;line-height:30px;height:30px;margin:0;font-size:14px;display:flex;flex-direction:row;justify-content:center">
              <p style="font-weight: bold">朝代:</p>{{ item.coupletDynasty }}
            </h1>
            <h1 style="width:23%;line-height:30px;height:30px;margin:0;font-size:14px;display:flex;flex-direction:row;justify-content:center">
              <p style="font-weight: bold">分类:</p>{{ item.coupletType }}
            </h1>
            <h1 style="width:23%;line-height:30px;height:30px;margin:0;font-size:14px;display:flex;flex-direction:row;justify-content:center">
              <p style="font-weight: bold">出处:</p>{{ item.coupletFrom }}
            </h1>
          </div>
          <div style="width:100%;height:60px;">
            <div
                style="width:90%;display:flex;align-items:center;flex-direction:row;justify-content:center">
              <h1 style="margin:0;font-weight: bold;line-height:30px;height:30px;width:65px;font-size:16px">
                上联:</h1>
              <p style="margin:0;line-height:30px;height:30px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;font-size:16px">
                {{ item.coupletFirstLine }}</p>
            </div>
            <div
                style="width:90%;display:flex;align-items:center;flex-direction:row;justify-content:center">
              <h1 style="margin:0;font-weight: bold;line-height:30px;height:30px;width:65px;font-size:16px">
                下联:</h1>
              <p style="margin:0;line-height:30px;height:30px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;font-size:16px">
                {{ item.coupletSecondLine }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-outline">
      <div style="width:95%;height:100%">
        <div style="width:100%;height:40px;display:flex;flex-direction:row;margin-top:-25px;">
          <el-divider style="width:5%"/>
          <el-link :underline="false" style="font-size: 15px;margin:0 5px">优秀作家展示</el-link>
          <el-divider style="max-width:78%"/>
        </div>
      </div>
      <div
          style="margin-left:15px;width:95%;height:100%;margin-top:-10px;display: flex;flex-direction:row;flex-wrap:wrap;">
        <div v-for="item in masterList" :key="item"
             style="width:300px;height:120px;margin:5px;border:1px solid lightgray;display:flex;flex-direction:row" @click="switchToMAsterDetail(item.masterId)">
          <div
              style="width:40%;height:100%;display:flex;flex-direction:row;align-items:center;justify-content:center">
            <img :src="item.masterPhoto" style="width:70%;height:70%;border-radius: 50%;"/>
          </div>
          <div
              style="width:60%;height:100%;display:flex;flex-direction: column;align-items:flex-start;">
            <p style="margin-top:20px;margin-bottom:10px;width:100%;height:10px;line-height:10px;font-size:16px;font-weight: bold;text-align:left;">
              {{ item.masterName }}</p>
            <p class="author">
              {{ item.masterIntroduction }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Home_Couplet",
  data() {
    return {
      coupletList: this.coupletListGet,
      masterList: this.masterListGet
    }
  },
  props: {
    coupletListGet: Array,
    masterListGet: Array
  },
  methods:{
    switchToCoupletDetail() {
      // this.$router.push({path: '/postDetails', query: {postId: postId, userId: this.userId}})
    console.log(this.masterList)
    },
    switchToMAsterDetail(masterId) {
      this.$router.push({path: '/masterDetail', query: {userId: masterId}})
    },
  },
  mounted(){
  }
}
</script>

<style scoped>
#content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.coupletTitle {
  width: 100%;
  height: 30px;
  background-color: #cccccc;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
}
.coupletTitle_content{
  font-size: 17px;
  font-weight: bold;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
  line-height: 100%;
}
.author {
  width: 100%;
  height: 100%;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  font-size: 14px;
}

.content-outline {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.el-divider {
  background-color: #cccccc;
  height: 2px;
}
</style>
