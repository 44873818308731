<template>
    <div>

        <el-form :model="couplet" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
                  style="margin-left: -30px;margin-right: 40px">

            <el-form-item label="作者" prop="name">
                <el-input v-model="couplet.coupletAuthorName"></el-input>
            </el-form-item>
            <el-form-item label="标题" prop="name">
                <el-input v-model="couplet.coupletTitle"></el-input>
            </el-form-item>
            <el-form-item label="类型">
                <el-input v-model="couplet.coupletType"></el-input>
            </el-form-item>
            <el-form-item label="上联" prop="name">
                <el-input type="textarea" v-model="couplet.coupletFirstLine"></el-input>
            </el-form-item>
            <el-form-item label="下联" prop="name">
                <el-input type="textarea" v-model="couplet.coupletSecondLine"></el-input>
            </el-form-item>


            <el-form-item label="赏析">
                <el-input type="textarea" v-model="couplet.coupletAppreaciation"></el-input>
            </el-form-item>
            <el-form-item label="注释">
                <el-input type="textarea" v-model="couplet.coupletExplanation"></el-input>
            </el-form-item>

        </el-form>
        <div ><el-button @click="goBack">返回</el-button> </div>
        <br/>

        <!--        评论区展示-->

        <div class="my-reply" style="width: 100%">
            <div style="display: flex; ">
                <div style="width:600px;margin-left: 60px;margin-right: 20px;">
                    <el-input placeholder="快来发表你的评论吧！" v-model="textarea"></el-input>
                </div>
                <el-button size="medium" type="primary" style="margin-left: 20px;"  @click="doComment">发表评论</el-button>
            </div>
            <br/>
            <div>
                <div v-for="(item,i) in coupletCommentList" :key="i" style="width: 100%;height: 100px;">
                    <div style="display: flex">
                        <div style="margin-left: 100px">
                            <el-avatar :size="40" :src="item.userPortrait"></el-avatar>
                        </div>
                        <div style="margin-left: 10px;text-align: left">
                            <span>{{item.userNickName}}</span><br/>
                            <span>{{item.coupletCommentTime}}</span>
                        </div>
                    </div>
                    <div style="text-align:left;margin-left: 150px">
                        {{item.coupletCommentContent}}
                    </div>
                </div>
                <br>
                <div class="footer" style="margin: 0 auto;width: 100%;">
                    <div class="block">
                        <el-pagination
                                background
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :page-size="pageSize"
                                layout="total, prev, pager, next, jumper"
                                :total=total>
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import {getCoupletData,getCoupletCommentList,doComment} from "../../apis/couplet";

    export default {
        data() {
            return {
                couplet: {
                    coupletId: '',
                    coupletTitle: '',
                    coupletAuthorName: '',
                    coupletFirstLine: '',
                    coupletSecondLine: '',
                    coupletAppreaciation: '',
                    coupletExplanation: '',
                    coupletType: '',
                },
                //评论相关参数
                textarea: '',
                coupletCommentList: [],
                pageNum: 1,
                pageSize: 5,
                total: 0,
            }
        },
        methods: {
            goBack() {
                this.$router.go(-1);
            },
            async getCoupletData() {
                const temp = await getCoupletData({
                    userId: window.localStorage.getItem('userId'),
                    coupletId: this.$route.query.coupletId
                });
                this.couplet = temp.data.coupletData
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            async getCoupletCommentList() {
                const temp = await getCoupletCommentList({
                    coupletId: this.$route.query.coupletId,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize
                })
                this.coupletCommentList = temp.data.coupletCommentList
                this.total = temp.data.total
            },
            async doComment(){
                let params = {
                    coupletCommentCoupletId: this.$route.query.coupletId,
                    coupletCommentUserId:window.localStorage.getItem('userId'),
                    coupletCommentContent:this.textarea
                };
                const temp=await doComment(params)
                console.log(temp)
                this.getCoupletCommentList()

            },
            handleCurrentChange(val) {
                this.pageNum = val
                this.getCoupletCommentList()
            }
        },
        mounted() {
            this.getCoupletData()
            this.getCoupletCommentList()
        }
    }

</script>


<style scoped>


</style>