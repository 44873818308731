<template>
  <div style="display:flex;flex-direction:column;width:100%">
    <span style="margin-left:20px;text-align:left;font-weight: bold;font-size: 16px;">阅读频道</span>
    <el-divider style="margin-top:0px;" border-style="double" content-position="left"/>
  </div>
  <div id="content">
    <div class="content-outline">
      <div style="width:95%">
        <div style="width:100%;height:40px;display:flex;flex-direction:row;margin-top:-25px;">
          <el-divider style="width:5%"/>
          <el-link :underline="false" style="font-size: 15px;margin:0 5px">联坛书籍</el-link>
          <el-divider style="max-width:82%"/>
        </div>
      </div>
      <div
          style="margin-left:15px;width:95%;height:100%;margin-top:-10px;display: flex;flex-direction:row;flex-wrap:wrap;">
        <div v-for="item in documentList" :key="item"
             style="width:300px;height:120px;margin:5px;display:flex;flex-direction:column;border:1px solid lightgray;">
          <div
              style="width:100%;height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center">
            <div style="width:100%;height: 25%;">
              <p style="width:97%;margin-left:3%;height:30px;line-height:30px;font-size:16px;font-weight: bold;text-align:left;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
                {{ item.documentName }}</p>
            </div>
            <div style="width:100%;height: 75%;display:flex;flex-direction:row;">
              <div
                  style="width:30%;height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center">
                <img :src="item.documentPicture" style="width:80%;height:90%;"/>
              </div>
              <div style="width:70%;height:100%;">
                <p class="books">
                  {{ item.documentIntroduction }}
                </p>
                <p style="width:100%;height:20%;text-align:left;">
                  评分：{{ item.documentScore }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-outline">
      <div style="width:90%;height:100%">
        <div style="width:100%;height:40px;display:flex;flex-direction:row;margin-top:-25px;">
          <el-divider style="width:5%"/>
          <el-link :underline="false" style="font-size: 15px;margin:0 5px">AI对联</el-link>
          <el-divider style="max-width:82%"/>
        </div>
      </div>
      <div
          style="width:90%;height:100%;margin-top:-10px;display: flex;flex-direction:column;align-items:center;justify-content:center">
        <img src="../../assets/images/AI.png" style="width:40%;height:35%;margin:-25px 0">
        <div style="width:100%;min-height:100px;">
          <p style="text-align:left;font-weight: bold;font-size:16px;line-height:30px;height:30px;margin: 0 0 0 20px">语句输入：</p>
          <div style="width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center">
               <div style="width:95%;">
                 <el-input v-model="coupletInput" placeholder="请输入上联" clearable />
               </div>
          </div>
          <div style="width:100%;display:flex;flex-direction:column;justify-content:center;align-items:flex-end">
            <el-button type="warning" style="border-radius:20px;width:150px;margin:5px 13px 5px 0 " @click="getAotuPost">生成结果</el-button>
          </div>
        </div>
        <div style="width:95%;min-height:120px;border-radius:5px;border:1px solid lightgray;">
            <p style="text-align:left;font-weight: bold;font-size:16px;line-height:30px;height:30px;margin: 0 0 0 5px">匹配结果：</p>
          <div
              style="width:96%;display:flex;align-items:center;flex-direction:row;justify-content:center">
            <h1 style="margin:0;font-weight: bold;line-height:30px;height:30px;width:65px;font-size:16px">
              下联1:</h1>
            <p style="text-align:left;margin:0;width:70%;line-height:29px;height:29px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;font-size:16px">
              {{autoResult[0]}}</p>
          </div>
          <div
              style="width:96%;display:flex;align-items:center;flex-direction:row;justify-content:center">
            <h1 style="margin:0;font-weight: bold;line-height:30px;height:30px;width:65px;font-size:16px">
              下联2:</h1>
            <p style="text-align:left;margin:0;width:70%;line-height:29px;height:29px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;font-size:16px">
              {{autoResult[1]}}</p>
          </div>
          <div
              style="width:96%;display:flex;align-items:center;flex-direction:row;justify-content:center">
            <h1 style="margin:0;font-weight: bold;line-height:30px;height:30px;width:65px;font-size:16px">
              下联3:</h1>
            <p style="text-align:left;margin:0;width:70%;line-height:29px;height:29px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;font-size:16px">
              {{autoResult[2]}}</p>
          </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getAotuPost} from "../../apis/home";

export default {
  name: "Home_Read",
  data() {
    let autoResult = []
    let coupletInput = "欲买桂花同载酒"
    return {
      documentList:this.documentListGet,
      autoResult,
      coupletInput
    }
  },
  methods: {
    async getAotuPost(){
      let res = await getAotuPost({input:this.coupletInput})
      let result = JSON.parse(res.data.result)
      this.autoResult = result.output
    }
  },
  props: {
    documentListGet:Array
  },
  mounted() {
    this.getAotuPost()
  }
}
</script>

<style scoped>
#content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.books {
  width: 100%;
  min-height: 50%;
  margin: 0;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  font-size: 14px;
}

.content-outline {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.el-divider {
  background-color: #cccccc;
  height: 2px;
}
</style>
