<template>
  <div style="height:100%">
  <el-carousel :interval="5000" arrow="always" height="350px">
    <el-carousel-item v-for="item in swiperList" :key="item" style="background-color:white">
      <div style="width:100%;height:100%;background-color:white">

        <img :src="item.newsImage" style="width:100%;height:100%;filter: blur(15px);-webkit-filter: blur(15px)"/>
        <img :src="item.newsImage" style="width:70%;height:100%;margin-top:-370px;filter: blur(0);-webkit-filter: blur(0)"/>
        <h1 style="filter: blur(0);-webkit-filter: blur(0);color:white;margin-top:-80px;width:90%;text-align:left;margin-left:40px">{{item.newsTitle}}</h1>
      </div>
    </el-carousel-item>
  </el-carousel>
  </div>
</template>

<script>

export default {
  name: "home_news",
  data(){
    return {
      swiperList:this.carousel_list,
    }
  },
  props: {
    carousel_list: Array,
  },
}
</script>

<style scoped>
.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.el-divider {
  background-color: #cccccc;
  height: 2px;
}
</style>
