<template>
    <div>
        <div class="show"  style="width:100%">
            <el-descriptions :column="1" border>

                <el-descriptions-item label="用户名"><p class="message">{{userInfo.userName}}</p></el-descriptions-item>
                <el-descriptions-item label="用户笔名" class="message"><p class="message">{{userInfo.userNickname}}</p>
                </el-descriptions-item>
                <el-descriptions-item label="用户生日" class="message"><p class="message">{{userInfo.userBirthday}}</p>
                </el-descriptions-item>
                <el-descriptions-item label="用户邮箱" class="message"><p class="message">{{userInfo.userEmail}}</p>
                </el-descriptions-item>
                <el-descriptions-item label="用户手机号" class="message"><p class="message">{{userInfo.userPhone}}</p>
                </el-descriptions-item>
                <el-descriptions-item label="用户居住地" class="message"><p class="message">{{userInfo.userLocation}}</p>
                </el-descriptions-item>
                <el-descriptions-item label="用户座右铭" class="message"><p class="message">{{userInfo.userMotto}}</p>
                </el-descriptions-item>
                <el-descriptions-item label="用户简介" class="message"><p class="message">{{userInfo.userIntroduction}}</p>
                </el-descriptions-item>

            </el-descriptions>
        </div>
    </div>
</template>


<script>

    import {getUserInformation} from "../../apis/user";

    export default {

        data() {
            return {
                userInfo: {
                    userId: '',
                    userNickname: '',
                    userPortrait: '',
                    userName: '',
                    userIntroduction: '',
                    userBirthday: '',
                    userLocation: '',
                    userPhone: '',
                    userEmail: '',
                    userMotto: '',
                    userType: '',
                    userApplyStatus: '',
                    userDuty: '',
                },
            }
        },
        methods: {
            onFinish() {

            },
            onFinishFailed() {

            },
            async getUserInformation() {
                const userInfo = await getUserInformation({
                    userId: window.localStorage.getItem('userId')
                })
                this.userInfo = userInfo.data.formData
            }
        },
        mounted() {
            this.getUserInformation()
        }
    }


</script>

<style scoped>
    .message {
        width: 40em;
        height: 100%;
        overflow: hidden;
        /*text-overflow: ellipsis; 文字多了省略号表示*/
        word-break: break-all;
        word-wrap: break-word
    }

    .analysis {
        width: 100%;
        height: calc(100%);
        overflow-y: scroll;
        overflow-x: hidden;
    }
</style>
