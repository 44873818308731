import request from "./request";


export const getCoupletListByCoupletType = async ({userId, coupletTypeId, pageSize, pageNum}) => {
    return await request.get('/couplet-server/couplet/getCoupletListByCoupletType', {
        params: {
            userId,
            coupletTypeId,
            pageSize,
            pageNum
        },
    })
}


export const getAllPersonalCouplet = async ({coupletAuthorId, pageSize, pageNum}) => {
    return await request.get('/couplet-server/couplet/getAllPersonalCouplet', {
        params: {
            coupletAuthorId,
            pageSize,
            pageNum
        },
    })
}

export const getSomeMasterList = async ({num}) => {
    return await request.get('/couplet-server/master/getSomeMasterList', {
        params: {
            num
        },
    })
}
export const getSomeCoupletList = async ({num}) => {
    return await request.get('/couplet-server/couplet/getSomeCoupletList', {
        params: {
            num
        },
    })
}
export const getNewAddMaster = async ({num}) => {
    return await request.get('/couplet-server/master/getNewAddMaster', {
        params: {
            num
        },
    })
}


export const getCoupletData = async ({userId, coupletId}) => {
    return await request.get('/couplet-server/couplet/getCoupletData', {
        params: {
            userId,
            coupletId
        },
    })
}

export const getCoupletType = async () => {
    return await request.get('/couplet-server/couplet-type/getCoupletType')
}

export const getCoupletCommentList = async ({coupletId, pageNum, pageSize}) => {
    return await request.get('/couplet-server/couplet-comment/getCoupletCommentList', {
        params: {
            coupletId,
            pageNum,
            pageSize
        },
    })
}

export const getCoupletCollectionList = async ({userId, pageNum, pageSize}) => {
    return await request.get('/couplet-server/couplet-collection/getCoupletCollectionList', {
        params: {
            userId,
            pageNum,
            pageSize
        },
    })
}

export const doComment = async ({
                                    coupletCommentContent,
                                    coupletCommentCoupletId,
                                    coupletCommentUserId
                                }) => {
    return await request.post('/couplet-server/couplet-comment/doComment', {
        params: {
            coupletCommentContent,
            coupletCommentCoupletId,
            coupletCommentUserId
        },
    })
}
export const getCoupletBrowseList = async ({userId, pageNum, pageSize}) => {
    return await request.get('/couplet-server/couplet-browse/getCoupletBrowseList', {
        params: {
            userId,
            pageNum,
            pageSize
        },
    })
}
export const deleteCoupletBrowse = async ({userId,coupletId}) => {
    return await request.post('/couplet-server/couplet-browse/deleteCoupletBrowse', {
        params: {
            userId,
            coupletId
        },
    })
}
export const getPersonalData = async ({masterId}) => {
    return await request.get('/couplet-server/master/getPersonalData', {
        params: {
            masterId
        },
    })
}
