<template>
      <Home_Carousel v-bind:carousel_list="swiperList" style="width:100%;" v-if="hadSwiperList"/>
      <Home_News v-bind:newsPictureListGet="newsPictureList" v-bind:tableDataGet="tableData" v-if="hadNewsPictureList&&hadTableData" style="width:100%;height:300px" />
      <Home_Couplet v-bind:coupletListGet="coupletList" v-bind:masterListGet="masterList" v-if="hadCoupletList&&hadMasterList" style="width:100%;height:300px"/>
      <Home_Read v-bind:documentListGet="documentList" v-if="hadDocumentList" style="width:100%;height:300px"/>
      <Home_Vedio v-bind:vedioListGet="vedioList" v-if="hadVedioList" style="width:100%;height:300px"/>
</template>
<script>
import {defineComponent} from "vue";
// import Header from "../components/Header.vue"
import Home_News from "../components/HomePage/Home_News.vue"
import Home_Carousel from "../components/HomePage/Home_Carousel.vue"
import Home_Couplet from "../components/HomePage/Home_Couplet";
import Home_Read from "../components/HomePage/Home_Read";
import Home_Vedio from "../components/HomePage/Home_Vedio";
import {
  getDocumentList,
  getNewsList,
  getSomeCoupletList,
  getSomeMasterList,
  getSwiperList, getVedio
} from "../apis/home";

export default defineComponent({
  name: "HomePage",
  components: { Home_News, Home_Carousel, Home_Couplet, Home_Read, Home_Vedio},
  data() {
    return {
      swiperList: [],
      coupletList:[],
      masterList:[],
      newsPictureList:[],
      tableData:[],
      documentList:[],
      autoResult:[],
      vedioList:[],
      hadSwiperList: false,
      hadCoupletList:false,
      hadMasterList:false,
      hadNewsPictureList:false,
      hadTableData:false,
      hadDocumentList:false,
      hadAutoResult:false,
      hadVedioList:false,
      getRequetsCount: 0
    }
  },
  methods: {
    async getSwiperList() {
      let res = await getSwiperList()
      this.swiperList = res.data.swiperList
      this.hadSwiperList = true
      this.getRequetsCount += 1
    },
    async getSomeCoupletList() {
      let res = await getSomeCoupletList({num: 6})
      this.coupletList = res.data.coupletList
      this.hadCoupletList = true
      this.getRequetsCount += 1
    },
    async getSomeMasterList() {
      let res = await getSomeMasterList({num: 6})
      this.masterList = res.data.masterList
      this.hadMasterList = true
      this.getRequetsCount += 1
    },
    async getNewsPictureList(){
      let res = await getNewsList({pageSize:6,pageNum:0})
      this.newsPictureList = res.data.newsList
      this.hadNewsPictureList = true
      this.getRequetsCount += 1
    },
    async getNewsTextList(){
      let res = await getNewsList({pageSize:20,pageNum:0})
      this.tableData = res.data.newsList
      this.hadTableData = true
      this.getRequetsCount += 1
    },
    async getDocumentList() {
      let res = await getDocumentList({pageSize: 6, pageNum: 0, type: 1})
      this.documentList = res.data.documentList
      this.hadDocumentList = true
      this.getRequetsCount += 1
    },
    async getVedioList() {
      let res = await getVedio({pageNum: 0, pageSize: 10})
      this.vedioList = res.data.coupletClassList
      this.hadVedioList = true
      this.getRequetsCount += 1
    },
  },
  created() {
    this.getSwiperList()
    this.getSomeCoupletList()
    this.getSomeMasterList()
    this.getNewsPictureList()
    this.getNewsTextList()
    this.getDocumentList()
    this.getVedioList()
  }
})
</script>
<style scoped>
.el-carousel__item h3 {
  color: black;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
