<template>
      <div class="master-information">
        <div>
          <el-image :src="master.userPortrait" fit="fill" class="master-image"/>
        </div>
        <div class="master-info">
          <div class="master-name">
            姓名：<span class="name-span">{{ master.userName }}</span>
          </div>
          <div>个人简介：{{ master.userIntroduction }}</div>
        </div>
        <div class="master-other">
          <div class="attention">
            关注 <span class="bold-span">{{ master.attentions }}</span>
          </div>
          <div class="fan">
            粉丝 <span class="bold-span">{{ master.fans }}</span>
          </div>
          <el-button
              v-if="!master.isAttention"
              class="attention-button"
              type="primary"
              icon="Plus"
              @click="isAttention"
          >关注
          </el-button
          >
          <el-button v-else type="info" icon="Check" @click="isAttention">已关注</el-button>
        </div>
      </div>
      <div style="width: 100%;margin-top: 10px;border-bottom: 2px solid #dddddd;">
        <div class="top-back">
          <el-button @click="goBack">返回</el-button>
        </div>
      </div>
      <div class="menu-wrap">
        <el-menu
            :default-active="activeIndex"
            class="production-menu"
            mode="horizontal"
            @select="handleSelect"
        >
<!--          只展示楹联-->
<!--          <el-menu-item :index="1">帖子</el-menu-item>-->
          <el-menu-item :index="2">楹联</el-menu-item>
        </el-menu>
        <div class="total">
          共<span class="bold-span">{{ total }}</span>条数据
        </div>
      </div>
      <div class="production-wrap">
        <div v-if="activeIndex === 1">
          <div class="production-line">
            <div
                v-for="post in postList.slice(0, 3)"
                :key="post.postId"
                class="production"
                @click="switchToPostDetail(post.postId)"
            >
              <div class="title">{{ post.postTitle }}</div>
              <div class="info">
                <!--                            <div>-->
                <!--                                朝代 <span class="info-span">{{ post.postDynasty }}</span>-->
                <!--                            </div>-->
                <div>
                  类型 <span class="info-span">{{ post.postType }}</span>
                </div>
                <!--                            <div>-->
                <!--                                出处 <span class="info-span">{{ post.postFrom }}</span>-->
                <!--                            </div>-->
              </div>
              <div class="first-line">{{ post.postFirstLine }}</div>
              <div class="second-line">{{ post.postSecondLine }}</div>
            </div>
          </div>
          <div class="production-line">
            <div
                v-for="post in postList.slice(3, 6)"
                :key="post.postId"
                class="production"
                @click="switchToPostDetail(post.postId)"
            >
              <div class="title">{{ post.postTitle }}</div>
              <div class="info">
                <!--                            <div>-->
                <!--                                朝代<span class="info-span">{{ post.postDynasty }}</span>-->
                <!--                            </div>-->
                <div>
                  类型<span class="info-span">{{ post.postType }}</span>
                </div>
                <!--                            <div>-->
                <!--                                出处<span class="info-span">{{ post.postFrom }}</span>-->
                <!--                            </div>-->
              </div>
              <div class="first-line">{{ post.postFirstLine }}</div>
              <div class="second-line">{{ post.postSecondLine }}</div>
            </div>
          </div>
          <div class="production-line">
            <div
                v-for="post in postList.slice(6, 9)"
                :key="post.postId"
                class="production"
                @click="switchToPostDetail(post.postId)"
            >
              <div class="title">{{ post.postTitle }}</div>
              <div class="info">
                <!--                            <div>-->
                <!--                                朝代<span class="info-span">{{ post.postDynasty }}</span>-->
                <!--                            </div>-->
                <div>
                  类型<span class="info-span">{{ post.postType }}</span>
                </div>
                <!--                            <div>-->
                <!--                                出处<span class="info-span">{{ post.postFrom }}</span>-->
                <!--                            </div>-->
              </div>
              <div class="first-line">{{ post.postFirstLine }}</div>
              <div class="second-line">{{ post.postSecondLine }}</div>
            </div>

          </div>
          <div class="footer">
            <div class="block" style="margin-left: 500px">
              <el-pagination
                  background
                  @current-change="handleCurrentPostChange"
                  :page-size="pageSize"
                  layout="total, prev, pager, next, jumper"
                  :total=total>
              </el-pagination>
            </div>
          </div>
        </div>
        <!-- 这个下面需要换成couplet的数据，字段需要换一下 -->
        <div v-else>
          <div class="production-line">
            <div
                v-for="couplet in coupletList.slice(0, 3)"
                :key="couplet.coupletId"
                class="production"
                @click="switchToCoupletDetail(couplet.coupletId)"
            >
              <div class="title">{{ couplet.coupletTitle }}</div>
              <div class="info">
                <!--                            <div>-->
                <!--                                朝代<span class="info-span">{{ couplet.coupletDynasty }}</span>-->
                <!--                            </div>-->
                <div>
                  类型<span class="info-span">{{ couplet.coupletType }}</span>
                </div>
                <!--                            <div>-->
                <!--                                出处<span class="info-span">{{ couplet.coupletFrom }}</span>-->
                <!--                            </div>-->
              </div>
              <div class="first-line">{{ couplet.coupletFirstLine }}</div>
              <div class="second-line">{{ couplet.coupletSecondLine }}</div>
            </div>
          </div>
          <div class="production-line">
            <div
                v-for="couplet in coupletList.length > 3 ? coupletList.slice(3, 6) : []"
                :key="couplet.coupletId"
                class="production"
                @click="switchToCoupletDetail(couplet.coupletId)"
            >
              <div class="title">{{ couplet.coupletTitle }}</div>
              <div class="info">
                <!--                            <div>-->
                <!--                                朝代<span class="info-span">{{ couplet.coupletDynasty }}</span>-->
                <!--                            </div>-->
                <div>
                  类型<span class="info-span">{{ couplet.coupletType }}</span>
                </div>
                <!--                            <div>-->
                <!--                                出处<span class="info-span">{{ couplet.coupletFrom }}</span>-->
                <!--                            </div>-->
              </div>
              <div class="first-line">{{ couplet.coupletFirstLine }}</div>
              <div class="second-line">{{ couplet.coupletSecondLine }}</div>
            </div>
          </div>
          <div class="production-line">
            <div
                v-for="couplet in coupletList.length > 6 ? coupletList.slice(6, 9) : []"
                :key="couplet.coupletId"
                class="production"
                @click="switchToCoupletDetail(couplet.coupletId)"
            >
              <div class="title">{{ couplet.coupletTitle }}</div>
              <div class="info">
                <!--                            <div>-->
                <!--                                朝代<span class="info-span">{{ couplet.coupletDynasty }}</span>-->
                <!--                            </div>-->
                <div>
                  类型<span class="info-span">{{ couplet.coupletType }}</span>
                </div>
                <!--                            <div>-->
                <!--                                出处<span class="info-span">{{ couplet.coupletFrom }}</span>-->
                <!--                            </div>-->
              </div>
              <div class="first-line">{{ couplet.coupletFirstLine }}</div>
              <div class="second-line">{{ couplet.coupletSecondLine }}</div>
            </div>
          </div>
          <div class="footer">
            <div class="block" style="margin-left: 500px">
              <el-pagination
                  background
                  @current-change="handleCurrentCoupletChange"
                  :page-size="pageSize"
                  layout="total, prev, pager, next, jumper"
                  :total=total>
              </el-pagination>
            </div>
          </div>
        </div>

      </div>
</template>

<script>
import {getAllPersonalCouplet} from '../../apis/couplet'
import {getAllPersonalPost} from '../../apis/post'
import {
  getFanList,
  getAttentionList,
  getRelationship,
  doAttention,
  cancelAttention,
  getUserInformation
} from '../../apis/user'

export default {
  name: "MasterDetail",
  data() {
    return {
      userId: this.$route.query.userId,
      master: {
        userPortrait: '',
        userName: '',
        userMotto: '',
        userIntroduction: '',
        fans: 0,
        attentions: 0,
        isAttention: false,
      },
      activeIndex: 1,
      coupletList: [],
      postList: [],
      pageSize: 9,
      pageNum: 1,
      total: 0,
    }
  },
  methods: {
    async getUserInformation() {
      const temp = await getUserInformation({
        userId: this.userId
      })
      this.master = temp.data.formData
      this.master['isAttention'] = true
    },
    async getFanList() {
      const temp = await getFanList({
        userId: this.userId,
        pageSize: 1,
        pageNum: 1
      })
      this.master['fans'] = temp.data.total
    },
    async getAttentionList() {
      const temp = await getAttentionList({
        userId: this.userId,
        pageSize: 1,
        pageNum: 1
      })
      this.master['attentions'] = temp.data.total
    },
    async getRelationship() {
      if (window.localStorage.getItem('userId') === this.userId) {
        this.master['isAttention'] = true
      } else {
        const temp = await getRelationship({
          relationshipUser1: window.localStorage.getItem('userId'),
          relationshipUser2: this.userId
        })
        this.master['isAttention'] = temp
      }

    },
    async doAttention() {
      await doAttention({
        userId: window.localStorage.getItem('userId'),
        userId2: this.userId
      })
      this.getFanList()
      this.getAttentionList()
    },
    async cancelAttention() {
      await cancelAttention({
        userId: window.localStorage.getItem('userId'),
        userId2: this.userId
      })
      this.getFanList()
      this.getAttentionList()
    },
    isAttention() {
      if (this.master['isAttention']) {
        this.cancelAttention()
        this.master['isAttention'] = false
      } else {
        this.doAttention()
        this.master['isAttention'] = true
      }

    },
    async getAllPersonalPost() {
      const temp = await getAllPersonalPost({
        userId: this.userId,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      })
      this.postList = temp.data.postList
      this.total = temp.data.total
    },
    async getAllPersonalCouplet() {
      const temp = await getAllPersonalCouplet({
        coupletAuthorId: this.userId,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      })
      this.coupletList = temp.data.coupletList
      this.total = temp.data.total
    },

    handleCurrentPostChange(val) {
      this.pageNum = val
      this.getAllPersonalPost()
      console.log(`当前页: ${val}`);
    },
    handleCurrentCoupletChange(val) {
      this.pageNum = val
      this.getAllPersonalCouplet()
      console.log(`当前页: ${val}`);
    },
    handleSelect(key, keyPath) {
      this.pageSize = 9
      this.pageNum = 1
      this.activeIndex = key
      if (key === 1) {
        this.getAllPersonalPost()
      } else {
        this.getAllPersonalCouplet()
      }
      console.log(key, keyPath);
    },
    switchToCoupletDetail(coupletId) {
      this.$router.push(
          {path: '/coupletDetails', query: {coupletId: coupletId, userId: this.userId}})
    },
    switchToPostDetail(postId) {
      this.$router.push({path: '/postDetails', query: {postId: postId, userId: this.userId}})
    },
    goBack() {
      this.$router.go(-1)
    }
  },
  mounted() {
    this.getUserInformation()
    this.getFanList()
    this.getAttentionList()
    this.getRelationship()
    this.getAllPersonalPost()
  }
};
</script>

<style scoped>
.top-back {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 18px;
  color: black;
  font-weight: bold;
  text-align: left;
  padding: 10px 20px;
  margin-bottom: 2px;
  margin-left: 1280px;
}

.master-information {
  width:100%;
  padding: 10px 10px;
  background-color: #e7e7e7;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.master-image {
  width: 200px;
  height: 200px;
  border-radius: 20px;
  overflow: hidden;
}

.master-info {
  width:100%;
  text-align: left;
  margin: 0 20px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: left;
  overflow: hidden;
}

.master-name {
  margin-bottom: 8px;
}

.name-span {
  font-size: 20px;
  font-weight: bold;
}

.bold-span {
  font-weight: bold;
}

.master-motto {
  margin-bottom: 8px;
}

.master-other {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
}

.menu-wrap {
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu-wrap /deep/  .el-menu{
  background-color: transparent;
}
.menu-wrap /deep/ .el-menu-item.is-active {
  background-color: transparent !important;
}
.menu-wrap /deep/ .el-menu-item:hover {
  background-color: transparent !important;
}
.production-menu {
  flex: 1;
}

.total {
  flex: 1;
  font-size: 18px;
  line-height: 59px;
  padding-right: 20px;
  text-align: right;
  border-bottom: 1px solid #dcdfe6;
}

.production-wrap {
  margin: 0 10px;
}

.production-line {
  width:100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 20px;
}

.production {
  width: 400px;
  margin: 10px 0;
  margin-right: 10px;
}

.production:last-child {
  margin-right: 0px;
}

.title {
  font-size: 16px;
  width: 100%;
  background-color: #cccccc;
}

.info {
  display: flex;
  font-size: 10px;
  margin: 4px 0;
  justify-content: center;
  align-items: center;
}

.info-span {
  color: purple;
  padding-left: 4px;
  padding-right: 8px;
}

.info-span:last-child() {
  padding-right: 0px;
}

.first-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.second-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
</style>
