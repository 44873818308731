<template>
  <div class="post-publish">
    <div class="tip">对联发布</div>
    <div class="bottom-tip">请勿发布不和谐、非原创作品</div>
    <el-form :model="postForm" label-width="80px" ref="postFormRef" :rules="rules">
      <el-form-item label="作者" prop="username">
        <el-input v-model="postForm.username" placeholder="请输入姓名" />
      </el-form-item>
      <el-form-item label="类型" prop="postTypeId">
        <el-select v-model="postForm.postTypeId" placeholder="请选择类型">
          <el-option
            v-for="item in postTypeList"
            :key="item.postTypeId"
            :label="item.postTypeDetail"
            :value="item.postTypeId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="标题" prop="postTitle">
        <el-input v-model="postForm.postTitle" placeholder="请输入标题" />
      </el-form-item>
      <el-form-item label="上联" prop="postFirstLine">
        <el-input
          v-model="postForm.postFisrtLine"
          :autosize="{ minRows: 2, maxRows: 4 }"
          type="textarea"
          placeholder="请输入上联"
        />
      </el-form-item>
      <el-form-item label="下联" prop="postSecondLine">
        <el-input
          v-model="postForm.postSecondLine"
          :autosize="{ minRows: 2, maxRows: 4 }"
          type="textarea"
          placeholder="请输入下联"
        />
      </el-form-item>

      <el-form-item label="注释" prop="postExplanation">
        <el-input
          v-model="postForm.postExplanation"
          :autosize="{ minRows: 2, maxRows: 4 }"
          type="textarea"
          placeholder="请输入注释"
        />
      </el-form-item>
      <el-form-item label="赏析" prop="postAppreaciation">
        <el-input
          v-model="postForm.postAppreaciation"
          :autosize="{ minRows: 2, maxRows: 4 }"
          type="textarea"
          placeholder="请输入赏析"
        />
      </el-form-item>
      <el-form-item style="margin-left: 220px">
        <el-button type="primary" icon="Position" @click="onSubmit">发布</el-button>
        <el-button type="warning" icon="Remove" @click="onReset">清空</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

  import {getPostTypes,addPost} from '../../apis/post'

export default {
  name: "PostPublish",
  data() {
    return {
      postForm: {
        username: "",
        postTypeId: "",
        postTitle: "",
        postFirstLine: "",
        postSecondLine: "",
        postExplanation: "",
        postAppreaciation: "",
      },
      postTypeList: [],
      rules: {
        username: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        postTypeId: [{
          required: true,
          message: "请选择类型",
          trigger: ["blur", "change"],
        }],
        postTitle: [{
          required: true,
          message: "请输入标题",
          trigger: "blur",
        }],
        postFirstLine: [{
          required: true,
          message: "请输入上联",
          trigger: "blur",
        }],
        postSecondLine: [{
          required: true,
          message: "请输入下联",
          trigger: "blur",
        }],
      },
    };
  },
  mounted() {
    this.getPostTypes()
    // 拿到登陆后的用户名
    const username =  window.localStorage.getItem('username');
    this.postForm['username'] = username;
  },
  methods: {
    onSubmit() {
      // this.$refs.postFormRef.validate((valid) => {
      //   if (valid) {
      //     // 提交
      //     console.log(this.postForm.postTypeId)
      //   } else {
      //     return false;
      //   }
      // });
      console.log(this.postForm)
    },

    onReset() {
      this.postForm = {
        username: "",
        postTypeId: "",
        postTitle: "",
        postFirstLine: "",
        postSecondLine: "",
        postExplanation: "",
        postAppreaciation: "",
      };
      this.$refs.postFormRef.resetFields();
    },
    async getPostTypes(){
      const temp=await getPostTypes()
      console.log(temp)
      this.postTypeList=temp.data.postType
    },
    async addPost(){
      const temp=await addPost(this.postForm)
      if (temp.code===20000){
        this.$message({
          message: '发布成功',
          type: 'success'
        });
      }

    },
  },
};
</script>

<style scoped>
.post-publish{
  margin: 20px 20px 0 0;
  padding-bottom: 20px;
}
.tip{
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  margin: 0 0 20px 40px;
}
.bottom-tip{
  margin: 0 0 20px 40px;
  font-size: 14px;
  text-align: left;
  color: #808080;
}
.bottom-tip::before{
  content: "* ";
  color: red;
  font-size: 14px;
}
</style>