<template>
  <div class="common-layout">
    <el-container>
      <el-aside id="sidebar">
        <el-menu
            default-active="1-1"
            collapse-transition
            :default-openeds="['1','2','3']"
            class="el-menu-vertical"
            style="width: 200px">
          <el-sub-menu index="1">
            <template #title>
              <span>我的服务</span>
            </template>
            <el-menu-item class="menu" index="1-1"
                          @click="switchTo('/personalCenter/basicInformation')">
              <span>基本信息</span>
            </el-menu-item>
            <el-menu-item class="menu" index="1-2" @click="switchTo('/personalCenter/myPost')">
              <span>我的帖子</span>
            </el-menu-item>
            <el-menu-item class="menu" index="1-3" @click="switchTo('/personalCenter/myCouplet')">
              <span>我的楹联</span>
            </el-menu-item>
            <el-menu-item class="menu" index="1-4" @click="switchTo('/personalCenter/myBookshelf')">
              我的书架
            </el-menu-item>
            <el-menu-item class="menu" index="1-5"
                          @click="switchTo('/personalCenter/myCollection')">
              <span>我的收藏</span>
            </el-menu-item>
            <el-menu-item class="menu" index="1-6" @click="switchTo('/personalCenter/myFocus')">
              <span>我的关注</span>
            </el-menu-item>
            <el-menu-item class="menu" index="1-7" @click="switchTo('/personalCenter/myFan')">
              <span>我的粉丝</span>
            </el-menu-item>
            <el-menu-item class="menu" index="1-8"
                          @click="switchTo('/personalCenter/myManuscript')">
              <span>我的投稿</span>
            </el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="2">
            <template #title>
              <span>个人服务</span>
            </template>
            <el-menu-item class="menu" index="2-1"
                          @click="switchTo('/personalCenter/systemNotification')">系统通知
            </el-menu-item>
            <el-menu-item class="menu" index="2-2"
                          @click="switchTo('/personalCenter/recentlyViewed')">最近浏览
            </el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="3">
            <template #title>
              <span>创作中心</span>
            </template>
            <el-menu-item class="menu" index="3-1" @click="switchTo('/personalCenter/postPublish')">
              对联发布系统
            </el-menu-item>
            <el-menu-item class="menu" index="3-2">文章发布系统</el-menu-item>
          </el-sub-menu>
        </el-menu>
      </el-aside>

      <el-container>
        <div style="height: 1000px;width: 1300px">
          <el-header id="top">
            <div style="display: flex;height: 200px;width: 800px">
              <div style="margin-top: 40px;width: 100px">
                <el-avatar :size="size" :src="this.userInfo.userPortrait" :fit="fit"/>
              </div>
              <div
                  style="margin-top: 25px;width: 400px;margin-left: 20px;height: 150px;display: block">
                <div style="height: 30px;font-size: 20px;text-align: left">
                  {{ this.userInfo.userName }}
                </div>
                <div style="height: 60px;text-align: left;padding-top: 20px">
                  <div>
                    座右铭:{{ this.userInfo.userMotto }}
                  </div>
                  <div class="analysis">
                    个人简介:{{ this.userInfo.userIntroduction }}
                  </div>
                </div>
              </div>
              <div style="width: 200px;display: flex;margin-top: 40px">
                <div style="background: #dfe0c6;width: 80px;margin-left: 20px;margin-top: 20px;height: 65px;
                  box-shadow: 10px 10px 5px #888888;
                  text-align: center;
                    ">
                  <el-link href="header" :underline="false" type="info "
                           style="font-size: 20px;margin-top: 4px">关注了
                  </el-link>
                  <br>
                  <el-link href="header" type="info " style="font-size: 20px;">0</el-link>
                  <br>
                </div>
                <div style="background: #dfe0c6;width: 80px;margin-left: 20px;margin-top: 20px;height: 65px;
                  box-shadow: 10px 10px 5px #888888;
                  text-align: center;
                    ">
                  <el-link href="header" :underline="false" type="info "
                           style="font-size: 20px;margin-top: 4px">关注
                  </el-link>
                  <br>
                  <el-link href="header" type="info " style="font-size: 20px;">12</el-link>
                  <br>
                </div>
              </div>
            </div>
          </el-header>

          <el-main id="content">
            <div>
              <div style="width: 100%;margin-left: 320px;margin-bottom: 5px">
                <el-button type="warning" @click="switchTo('/')">返回首页</el-button>
              </div>
            </div>
            <div>
              <router-view></router-view>
            </div>

          </el-main>

          <el-footer>
            <!--             Footer-->
          </el-footer>
        </div>


      </el-container>
    </el-container>


  </div>
</template>


<script>
import {getUserInformation} from '../apis/user'

export default {
  data() {
    return {
      circleUrl: '',
      size: 100,
      fit: 'scale-down',
      userInfo: {
        userId: '',
        userNickname: '',
        userPortrait: '',
        userName: '',
        userIntroduction: '',
        userBirthday: '',
        userLocation: '',
        userPhone: '',
        userEmail: '',
        userMotto: '',
        userType: '',
        userApplyStatus: '',
        userDuty: '',
      }
    }
  },
  methods: {
    async getUserInformation() {
      const userInfo = await getUserInformation({
        userId: window.localStorage.getItem('userId')
      })
      this.userInfo = userInfo.data.formData
    },
    switchTo(path) {
      this.$router.push(path)
    }
  },
  mounted() {
    this.$router.push('/personalCenter/basicInformation')
    this.getUserInformation()
  }
}
</script>

<style scoped>
.common-layout {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

#sidebar {
  width: 200px;
  height: 100%;
  background-color: white;
  margin-left: 180px;
  margin-top: 250px;
}

#top {
  width: 800px;
  height: 200px;
  margin-left: 60px;
  margin-top: 40px;
  background-color: #ffffff;
  display: flex;
  box-shadow: 10px 10px 5px #888888;
}

.analysis {
  width: 100%;
  height: calc(200%);
  overflow-y: scroll;
  overflow-x: hidden;
}

#content {
  width: 800px;
  /*height: 900px;*/
  overflow-x: hidden;
  margin-left: 60px;
  margin-top: 30px;
  background-color: rgb(255, 255, 255)
}
</style>
