<template>
  <div class="page-header">
    <div style="width:100%;height:30px;display:flex">
      <div id="header-location">
        <iframe scrolling="no" src="https://tianqiapi.com/api.php?style=tx&skin=pitaya"
                frameborder="0" style="margin:3px 30px" height="24px" width="100%"
                allowtransparency="true"></iframe>
      </div>
      <div id="header-personal">
        <p>
          <UserFilled class="header-header" style="width:20px;margin:0"/>
        </p>
        <el-button link
                   style="margin-right: 30px;height:30px;width:40px;text-align: center;line-height: 30px;"
                   @click="switchToPersonalCenter">登录
        </el-button>
        <p>
          <ChatLineSquare class="header-header" style="width:20px;margin:0"/>
        </p>
        <el-button link
                   style="margin-right: 30px;height:30px;width:60px;text-align: center;line-height: 30px;">
          联系我们
        </el-button>
      </div>
    </div>
    <div id="header">
      <div id="header-logo"></div>
      <div id="header-function-class">
        <p class="function-name"></p>
        <p class="function-name">阅读:</p>
        <p class="function-name">查询:</p>
        <p class="function-name">韵书:</p>
        <p class="function-name">校验:</p>
      </div>
      <div id="header-function">
        <div class="function-row"></div>
        <div class="function-row">
          <el-button class="btn-content" link style="margin-left:5px;">历代联话</el-button>
          <el-button class="btn-content" link style="margin-left:5px;">古今联书</el-button>
          <el-button class="btn-content" link style="margin-left:5px;">民间联刊</el-button>
          <el-button class="btn-content" link style="margin-left:5px;">中华对联通论</el-button>
        </div>
        <div class="function-row">
          <el-button class="btn-content" link style="margin-left:5px;">联律通则</el-button>
          <el-button class="btn-content" link style="margin-left:5px;">典故查询</el-button>
          <el-button class="btn-content" link style="margin-left:5px;">对仗查询</el-button>
          <el-button class="btn-content" link style="margin-left:5px;">中华对联库</el-button>
        </div>
        <div class="function-row">
          <el-button class="btn-content" link style="margin-left:5px;">词林正韵</el-button>
          <el-button class="btn-content" link style="margin-left:5px;">中原音韵</el-button>
          <el-button class="btn-content" link style="margin-left:5px;">中华通韵</el-button>
          <el-button class="btn-content" link style="margin-left:5px;">平水韵</el-button>
        </div>
        <div class="function-row">
          <el-button class="btn-content" link style="margin-left:5px;">律诗校验</el-button>
          <el-button class="btn-content" link style="margin-left:5px;">词格校验</el-button>
          <el-button class="btn-content" link style="margin-left:5px;">对联校验</el-button>
          <el-button class="btn-content" link style="margin-left:5px;">典故查询</el-button>
          <el-button class="btn-content" link style="margin-left:5px;">在线联墨</el-button>
        </div>
      </div>
    </div>
    <div id="header-menu">
      <div class="menu-container">
        <el-button class="btn" type="text" style="width:100px;height:100%;color:white"
                   @click="switchTo('/home')">
          首页
        </el-button>
        <el-dropdown style="height:100%;">
          <el-button type="text" class="btn" style="width:100px;height:100%;color:white">
            新闻频道
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>联坛快讯</el-dropdown-item>
              <el-dropdown-item>联家访谈</el-dropdown-item>
              <el-dropdown-item>征稿启事</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown style="height:100%;">
          <el-button type="text" class="btn" style="width:100px;height:100%;color:white">
            对联频道
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>中华对联库</el-dropdown-item>
              <el-dropdown-item>对联赏析</el-dropdown-item>
              <el-dropdown-item>对联研究</el-dropdown-item>
              <el-dropdown-item>对联故事</el-dropdown-item>
              <el-dropdown-item>对联课堂</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-button class="btn" type="text" style="width:100px;height:100%;color:white"
                   @click="switchTo('/editor')">
          民联频道
        </el-button>
        <el-dropdown style="height:100%;">
          <el-button type="text" class="btn" style="width:100px;height:100%;color:white">
            阅读频道
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>古今联书</el-dropdown-item>
              <el-dropdown-item>历史联话</el-dropdown-item>
              <el-dropdown-item>民间联刊</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown style="height:100%;">
          <el-button type="text" class="btn" style="width:100px;height:100%;color:white"
                     @click="switchTo('/competitionChannel')">
            大赛频道
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>联坛动态</el-dropdown-item>
              <el-dropdown-item>组织机构</el-dropdown-item>
              <el-dropdown-item>协会报刊</el-dropdown-item>
              <el-dropdown-item>会员管理</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-button class="btn" type="text" style="width:100px;height:100%;color:white"
                   @click="switchTo('/chineseCouplets')">
          中国楹联家
        </el-button>
        <el-button class="btn" type="text" style="width:100px;height:100%;color:white"
                   @click="switchTo('/coupletSearch')">
          中华楹联库
        </el-button>
      </div>
      <div class="search-container">

      </div>
    </div>
  </div>
</template>

<script>

import {ref} from "vue";

// import {User} from "@element-icons"
export default {
  data() {
    const cityid = ref()
    const cityName = ref()
    const weather = ref()
    const temperatureLow = ref()
    const temperatureHigh = ref()
    const week = ref()
    return {
      cityid,
      cityName,
      weather,
      temperatureLow,
      temperatureHigh,
      week
    }
  },
  methods: {
    switchToPersonalCenter() {
      const {href} = this.$router.resolve({
        name: "personalCenter"
      });
      window.open(href, '_blank');
    },
    switchTo(path) {
      window.localStorage.setItem('userId', 'oJCKH4n9mTgSE5iUKOV1_hziV-hM')
      window.localStorage.setItem('username', '周拥军')
      this.$router.push(path)
    },
    switchToPersonnal(path) {
      window.localStorage.setItem('userId', 'oJCKH4n9mTgSE5iUKOV1_hziV-hM')
      window.localStorage.setItem('username', '周拥军')
      this.$router.push(path)
    }
  },
}
</script>


<style>
.page-header {
  width: 90%;
  /*background-color: red;*/
  display: flex;
  flex-direction: column;
  align-items: center;
}

#header-location {
  background-color: lightgray;
  width: 80%;
  height: 30px;
  line-height: 30px;
}

#header-personal {
  background-color: lightgray;
  width: 20%;
  height: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
}

#header {
  width: 100%;
  height: 140px;
  /*background-color: black;*/
  display: flex;
}

#header-logo {
  width: 70%;
  height: 140px;
  margin-left: 5%;
  align-items: flex-start;
  display: flex;
  background-image: url("../assets/images/logo.png");
  background-repeat: no-repeat;
  background-size: 30% 100%;
  -moz-background-size: 100% 100%;
}

#header-function {
  width: 30%;
  height: 140px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

#header-menu {
  width: 100%;
  height: 40px;
  background-color: black;
  display: flex;
  margin-top: 10px;
}

.header-header {
  margin: 0;
  height: 30px;
  width: 80px;
  text-align: center;
  line-height: 30px;
}

.function-row {
  height: 28px;
  width: 100%;
  vertical-align: middle;
  /*background-color: yellow;*/
  display: flex;
  align-items: flex-start;
  /*text-align: center;*/
}

#header-function-class {
  height: 100%;
  width: 45px;
  /*background-color: palevioletred;*/
}

.function-name {
  height: 28px;
  width: 100%;
  display: flex;
  line-height: 140%;
  text-align: center;
  align-items: flex-start;
  margin: 0;
  font-size: 15px;
  font-family: "仿宋";
  font-weight: bold;
  color: red;
}

.menu-container {
  width: 75%;
  height: 100%;
  /*background-color:yellow;*/
  margin-left: 20px;
  display: flex;
  align-items: flex-start;
}

.search-container {
  width: 25%;
  height: 100%;
  /*background-color:palegreen;*/
}

.btn-content {
  font-family: "华文仿宋";
  font-size: 15px;
  /* line-height:100%; */
}

.btn:hover {
  font-weight: bold;
}

.btn-content:hover {
  font-weight: bold;
}
</style>
