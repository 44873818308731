<template>
    <div>
        <div>
            <div style="height: 50px;display: flex;margin-left: 40px">
                <el-button @click="getMyManuscript">我的投稿</el-button>
                <el-button @click="getFinalist">获奖作品</el-button>
            </div>
            <div v-show="showMyManuscript">
                <!--        结果展示-->
                <div style="width: 80%;margin-left: 1%" class="main">
                    <el-card shadow="hover" v-for="(message,index) in manuscriptMessages" :key="index"
                             style="margin-left: 5%;width:700px ">

                        <div style="height:140px;margin-left: 0">
                            <div style="display:inline-block;margin-left: 0%;width: 80%">
                                <p class="message" style="font-weight:bold" >
                                    {{message.competitionName}}</p>
                                {{message.manuscriptUserName}} <br/>
                                <p style="font-weight: lighter" class="message">
                                    {{message.manuscriptContent}}<br/>
                                </p>
                                <p>&nbsp;收藏时间:&nbsp;{{message.manuscriptCreateTime}}</p>
                            </div>

                            <div style="width:18%;height: 100%;display:inline-block;">
                                <div style="display: inline-block;width: 50%;margin-top: 20%;margin-left: 24%">
                                    <el-tooltip class="item" effect="dark" content="查看详情" placement="top-start">
                                        <el-button type="primary" round
                                                   style="height: 50%;width: 100%;display: inline-block"
                                                   @click="viewManuscript(message.manuscriptId)">
                                            <el-icon :size="20">
                                                <Edit/>
                                            </el-icon>
                                        </el-button>
                                    </el-tooltip>
                                    <br><br>
                                </div>
                            </div>
                        </div>
                        <br>
                    </el-card>
                </div>
                <br>
                <div class="footer" style="margin: 0 auto;width: 100%;">
                    <div class="block">
                        <el-pagination
                                background
                                @size-change="handleSizeChange"
                                @current-change="handleCoupletCurrentChange"
                                :page-size="pageSize"
                                layout="total, prev, pager, next, jumper"
                                :total=total>
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

    import {getMyManuscript} from '../../apis/post'

    export default {
        data() {
            return {
                total: 5,
                pageSize: 4,
                pageNum: 1,
                manuscriptMessages: [],

            }
        },
        methods: {
            async getMyManuscript(){
              const temp=await getMyManuscript({
                  userId:window.localStorage.getItem('userId')
              })
                this.manuscriptMessages=temp.data.allManuscript
                this.total=temp.data.total
            },
            viewManuscript(manuscriptId){
                console.log(manuscriptId)
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.pageNum=val
                this.getMyManuscript()
                console.log(`当前页: ${val}`);
            }
        },
        mounted() {
            this.getMyManuscript()
        }

    }

</script>

<style scoped>


</style>