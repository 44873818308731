<template>
    <div>
        <div class="master-information">
            <div>
                <el-image :src="master.userPortrait" fit="fill" class="master-image"/>
            </div>
            <div class="master-info">
                <div class="master-name">
                    姓名：<span class="name-span">{{ master.userName }}</span>
                </div>
                <div>个人简介：{{ master.userIntroduction }}</div>
            </div>
        </div>

        <div style="width: 1400px;margin-top: 10px;border-bottom: 2px solid #dddddd;">
            <div class="top-back">
                <el-button @click="goBack">返回</el-button>
            </div>
        </div>
        <div style=" border-bottom: 2px solid #dddddd;">
            <div class="production-line">
                <div class="production">
                    <div class="title">{{ couplet.coupletTitle }}</div>
                    <div class="info">
                        <div>
                            朝代<span class="info-span">{{ couplet.coupletDynasty }}</span>
                        </div>
                        <div>
                            类型<span class="info-span">{{ couplet.coupletType }}</span>
                        </div>
                        <div>
                            出处<span class="info-span">{{ couplet.coupletFrom }}</span>
                        </div>
                    </div>
                    <div class="first-line">{{ couplet.coupletFirstLine }}</div>
                    <div class="second-line">{{ couplet.coupletSecondLine }}</div>
                    <p class="message">
                        浏览：
                        <i class="el-icon-view"></i>
                        {{couplet.coupletBrowses}}
                        &nbsp;&nbsp;
                        收藏：
                        <i class="el-icon-star-off"></i>
                        {{couplet.coupletCollections}}
                        &nbsp;&nbsp;
                        点赞：
                        <i class="el-icon-coordinate"></i>
                        {{couplet.coupletLikes}}
                        &nbsp;&nbsp;
                        评论：
                        <i class="el-icon-coordinate"></i>
                        {{couplet.coupletComments}}
                    </p>
                </div>
            </div>
            <div style="width: 1000px;margin-left: 200px;text-align: left;">
                <div> 注释： {{couplet.coupletExplanation}}</div>
                <div> 赏析： {{couplet.coupletAppreaciation}}</div>
            </div>
        </div>
        <br/>
        <!--        评论区展示-->

        <div class="my-reply" style="width: 100%">
            <div style="display: flex; margin-left: 260px;">
                <div style="width:600px;margin-right: 20px;">
                    <el-input placeholder="快来发表你的评论吧！" v-model="textarea"></el-input>
                </div>
                <el-button size="medium" type="primary" style="margin-left: 20px;" @click="doComment">发表评论</el-button>
            </div>
            <br/>
            <div>
                <div v-for="(item,i) in coupletCommentList" :key="i" style="width: 100%;height: 100px;">
                    <div style="display: flex">
                        <div style="margin-left: 100px">
                            <el-avatar :size="40" :src="item.userPortrait"></el-avatar>
                        </div>
                        <div style="margin-left: 10px;text-align: left">
                            <span>{{item.userNickName}}</span><br/>
                            <span>{{item.coupletCommentTime}}</span>
                        </div>
                    </div>
                    <div style="text-align:left;margin-left: 150px">
                        {{item.coupletCommentContent}}
                    </div>
                </div>
                <br>
                <div class="footer" style="margin-left: 500px;width: 100%;">
                    <div class="block">
                        <el-pagination
                                background
                                @current-change="handleCurrentChange"
                                :page-size="pageSize"
                                layout="total, prev, pager, next, jumper"
                                :total=total>
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getCoupletData, getCoupletCommentList, doComment} from "../../apis/couplet";
    import {getUserInformation} from "../../apis/user";

    export default {
        name: "MasterDetail",
        data() {
            return {
                userId: this.$route.query.userId,
                master: {
                    userPortrait:'',
                    userName: '',
                    userMotto: '',
                    userIntroduction:'',
                    fans: 0,
                    attentions: 0,
                    isAttention: false,
                },
                couplet: {
                    coupletId: '',
                    coupletTitle: '',
                    coupletAuthorName: '',
                    coupletFirstLine: '',
                    coupletSecondLine: '',
                    coupletAppreaciation: '',
                    coupletExplanation: '',
                    coupletType: '',
                },
                //评论相关参数
                textarea: '',
                coupletCommentList: [],
                pageNum: 1,
                pageSize: 5,
                total: 0,
            }
        },
        methods: {
            async getUserInformation() {
                const temp = await getUserInformation({
                    userId: this.userId
                })
                this.master = temp.data.formData
            },
            async getCoupletData() {
                const temp = await getCoupletData({
                    userId: window.localStorage.getItem('userId'),
                    coupletId: this.$route.query.coupletId
                });
                this.couplet = temp.data.coupletData
                if (this.couplet.coupletExplanation === '') {
                    this.couplet.coupletExplanation = '暂无'
                }
                if (this.couplet.coupletAppreaciation === '') {
                    this.couplet.coupletAppreaciation = '暂无'
                }
                console.log(this.couplet)
            },
            async getCoupletCommentList() {
                const temp = await getCoupletCommentList({
                    coupletId: this.$route.query.coupletId,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize
                })
                this.coupletCommentList = temp.data.coupletCommentList
                this.total = temp.data.total
            },
            async doComment() {
                let params = {
                    coupletCommentCoupletId: this.$route.query.coupletId,
                    coupletCommentUserId: window.localStorage.getItem('userId'),
                    coupletCommentContent: this.textarea
                };
                const temp = await doComment(params)
                console.log(temp)
                this.getCoupletCommentList()

            },
            handleCurrentChange(val) {
                this.pageNum = val
                this.getCoupletCommentList()
            },
            goBack() {
                this.$router.go(-1)
            }
        },
        mounted() {
            this.getUserInformation()
            this.getCoupletData()
            this.getCoupletCommentList()
        }
    }

</script>

<style scoped>
    .top-back {
        font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
        font-size: 18px;
        color: black;
        font-weight: bold;
        text-align: left;
        padding: 10px 20px;
        margin-bottom: 2px;
        margin-left: 1280px;
    }

    .master-information {
        margin: 10px 10px;
        padding: 10px 10px;
        background-color: #e7e7e7;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .name-span {
        font-size: 20px;
        font-weight: bold;
    }

    .master-name {
        margin-bottom: 8px;
    }

    .master-info {
        text-align: left;
        margin: 0 20px;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: left;
        overflow: hidden;
    }

    .master-image {
        width: 200px;
        height: 200px;
        border-radius: 20px;
        overflow: hidden;
    }

    .production-line {
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
        margin-bottom: 20px;
        margin-left: 550px;
    }

    .title {
        font-size: 16px;
        width: 100%;
        background-color: #cccccc;
    }

    .info {
        display: flex;
        font-size: 10px;
        margin: 4px 0;
        justify-content: center;
        align-items: center;
    }

    .info-span {
        color: purple;
        padding-left: 4px;
        padding-right: 8px;
    }

    .first-line {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
    }

    .second-line {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
    }
</style>