<template>
      <div style="width:100%;height:100%;display:flex;flex-direction:row;">
        <div style="width:65%;height:100%;">
          <h1 style="width:100%;text-align:left;font-weight:bold;margin:0">
            {{ vedioCurrentInfo.coupletClassTitle }}</h1>
          <div style="width:100%;">
            <vue3VideoPlay v-bind="options"/>
          </div>
          <p style="width:100%;text-align:left;margin:0">发布时间：{{ vedioCurrentInfo.createTime }}</p>
          <p style="width:100%;text-align:left;margin:0">
            简介：{{ vedioCurrentInfo.coupletClassDescription }}</p>
        </div>
        <div style="width:35%;height:530px;margin-top:9px;">
          <h2 style="width:100%;text-align:left;margin:0">播放列表</h2>
          <div style="width:100%;height:520px;overflow-y: scroll;">
            <div v-for="item in vedioList" :key="item"
                 style="width:100%;height:120px;margin-top:5px;margin-bottom:20px;overflow-x: hidden;display:flex;flex-direction:row"
                 @click="getClickVedio(item)">
              <div style="width:45%;height:100%;">
                <img :src="item.coupletClassImage" style="width:100%;height:100%;"/>
              </div>
              <div
                  style="width:55%;height:100%;display:flex;flex-direction:column;align-items:flex-start;">
                <el-link :underline="false" @click="getClickVedio(item)">
                  <p id="vedioListTitle">{{ item.coupletClassTitle }}</p>
                </el-link>
                <p style="width:100%;text-align:left;margin:3px;font-size:14px">对联云工作室</p>
                <p id="vedioCreateTime">
                  发布时间：{{ item.createTime }}</p>
              </div>
            </div>
          </div>

        </div>
      </div>

</template>

<script>
import "vue3-video-play/dist/style.css";
import vue3VideoPlay from "vue3-video-play";
import axios from "axios";
import {getCoupletClassById, getVedio} from "../../apis/home";

export default {
  components: {
    vue3VideoPlay,
  },
  data() {
    let vedio_url = ""
    let options = {}
    let vedioList = []
    let vedioCurrentInfo = {}
    return {
      vedio_url,
      options,
      vedioList,
      vedioCurrentInfo
    }
  },
  methods: {
    async getVedioList() {
      let res = await getVedio({pageNum: 0, pageSize: 10})
      if (res.code === 20000) {
        this.vedioList = res.data.coupletClassList
        console.log(this.vedioList)
      }
    },
    async getVedioInfo() {
      let res = await getCoupletClassById({coupletClassId: this.$route.query.vedioId})
      this.vedioCurrentInfo = res.data.coupletClass
      console.log(this.vedioCurrentInfo.coupletClass)
    },
    getClickVedio(item) {
      this.$router.push({
        path: '/coupletClass',
        query: {
          vedio_url: "/mp_weChat/" + item.coupletClassSrc.split("s/")[1],
          vedioId: item.coupletClassId
        }
      })
    },
    get_vedio_url() {
      axios
      // 3.1url地址
      .get(this.$route.query.vedio_url)
      // 3.2成功时回调函数
      .then((data) => {
        this.vedio_url = data.data.split(" window.__mpVideoTransInfo = ")[1].split("];")[0].split(
            "url:")[1].split("'")[1].split(
            "',")[0].replace("\\x26amp;", '&');
        console.log(this.vedio_url)
        this.options = {
          width: "90%", //播放器高度
          height: "500px", //播放器高度
          color: "#409eff", //主题色
          title: "", //视频名称
          src: this.vedio_url, //视频源
          muted: false, //静音
          webFullScreen: false,
          speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
          autoPlay: true, //自动播放
          loop: false, //循环播放
          mirror: false, //镜像画面
          ligthOff: true, //关灯模式
          volume: 0.3, //默认音量大小
          control: true, //是否显示控制
          controlBtns: [
            "audioTrack",
            "quality",
            "speedRate",
            "volume",
            "setting",
            "pip",
            "pageFullScreen",
            "fullScreen",
          ], //显示所有按钮,
        }
      })
      //3.3失败时回调函数
      .catch((err) => {
        console.log(err);
      });

    },
  },
  mounted() {
    this.getVedioInfo()
    this.get_vedio_url()
    this.getVedioList()
  },
  watch: {
    '$route'() {
      this.$router.go(0);
    }
  },
};
</script>

<style scoped>

#vedioListTitle {
  text-align: left;
  margin: 3px;
  font-size: 18px;
  line-height: 30px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*-webkit-line-clamp: 2;*/
}

#vedioCreateTime {
  width: 100%;
  text-align: left;
  margin: 3px;
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*-webkit-line-clamp: 2;*/
}
</style>
