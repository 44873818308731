import request from './request'

export const getVedio = async ({
  pageNum,
  pageSize
}) => {
  return await request.get('/file-server/couplet-class/getCoupletClassList', {
    params: {
      pageNum,
      pageSize
    },
  })
}

export const getSomeCoupletList = async ({num}) => {
  return await request.get('/couplet-server/couplet/getSomeCoupletList', {
    params: {
      num: num
    }
  })
}

export const getSomeMasterList = async ({num}) => {
  return await request.get('/couplet-server/master/getSomeMasterList', {
    params: {
      num: num
    }
  })
}
export const getSwiperList = async () => {
  return await request.get('/post-server/news/getSwiperList',)
}

export const getDocumentList = async ({pageSize, pageNum, type}) => {
  return await request.get('/file-server/document/getDocumentList', {
    params: {
      pageSize,
      pageNum,
      type
    }
  })
}
export const getNewsList = async ({pageSize, pageNum}) => {
  return await request.get('/post-server/news/getNewsList', {
    params: {
      pageSize,
      pageNum
    }
  })
}

export const getAotuPost = async ({input}) => {
  return await request.get('/post-server/post/getAotuPost', {
    params: {
      input
    }
  })
}

export const getCoupletClassById = async ({coupletClassId  }) => {
  return await request.get('/file-server/couplet-class/getCoupletClassById', {
    params: {
      coupletClassId
    }
  })
}





