<template>

    <div>
        <div>
            <div style="display: flex">
                <el-input v-model="input" placeholder="请输入内容" style="width: 400px;margin-left: 40px;margin-right: 40px" clearable="true"></el-input>
                <el-button type="primary" style="text-align: center" size="medium " @click="search">搜索</el-button>
            </div>
        </div>
        <br/>
        <div>
            <div style="width: 80%;margin-left: 1%" class="main">
                <el-card shadow="hover" v-for="(item,i) in attentionList" :key="i"
                         style="margin-left: 5%;width:700px ">
                        <div  style="width: 100%;height: 100px;">
                            <div style="display: flex">
                                <div style="margin-left: 20px">
                                    <el-avatar :size="40" :src="item.userPortrait"></el-avatar>
                                </div>
                                <div style="margin-left: 10px;text-align: left">
                                    <span>{{item.userNickname}}</span><br/>
                                </div>
                            </div>
                            <div style="text-align:left;margin-left: 50px" class="introduce">
                                {{item.userIntroduction}}
                            </div>
                        </div>
                </el-card>
                <br/>
                <div class="footer" style="margin: 0 auto;width: 100%;">
                    <div class="block">
                        <el-pagination
                                background
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :page-size="pageSize"
                                layout="total, prev, pager, next, jumper"
                                :total=total>
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import {getAttentionList,searchAttention} from '../../apis/user'

    export default {
        data(){
            return{
                input:'',
                attentionList:[],
                pageSize:5,
                pageNum:1,
                total:0,


            }
        },
        methods:{
            async getAttentionList(){
                const  temp=await getAttentionList({
                    userId:window.localStorage.getItem('userId'),
                    pageSize:this.pageSize,
                    pageNum:this.pageNum
                })
                this.attentionList=temp.data.attentionList
                console.log(this.attentionList)
                this.total=temp.data.total
            },
            async searchAttention(){
                const  temp=await searchAttention({
                    userId:window.localStorage.getItem('userId'),
                    inputKey:this.input,
                    pageSize:this.pageSize,
                    pageNum:this.pageNum
                })
                this.attentionList=temp.data.attentionList
                console.log(this.attentionList)
                this.total=temp.data.total
            },
            search(){
                console.log(this.input)
                if (this.input===''){
                    this.getAttentionList()
                }else {
                    this.searchAttention()
                }
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.pageNum=val
                this.getAttentionList()
                console.log(`当前页: ${val}`);
            },

        },
        mounted() {
            this.getAttentionList()
        }
    }

</script>

<style scoped>
.introduce{
    overflow:hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;

}


</style>