<template>
    <div>
        <div>
            <el-card shadow="hover" v-for="(item,i) in documentList" :key="i"
                     style="margin-left: 5%;width:700px ">
                <div  style="width: 100%;height: 100px;">
                    <div style="display: flex">
                        <div style="margin-left: 20px">
                            <el-avatar :size="40" :src="item.documentPicture"></el-avatar>
                        </div>
                        <div style="margin-left: 10px;text-align: left;width: 400px">
                            <span>{{item.documentName}}</span><br/>
                           分数:<span>{{item.documentScore}}</span> &nbsp;
                            收藏数:<span>{{item.documentCollections}}</span><br/>
                        </div>
                        <el-button type="primary" style="margin-left: 50px" @click="viewDocumentDetail(item.documentId)">查看详情</el-button>
                    </div>
                    <div style="text-align:left;margin-left: 50px" class="introduce">
                        {{item.documentIntroduction}}
                    </div>

                </div>
            </el-card>
            <br/>
            <div class="footer" style="margin: 0 auto;width: 100%;">
                <div class="block">
                    <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total=total>
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

    import {getDocumentList} from "../../apis/file";

    export default {
        data(){
            return{
                documentList:[],
                pageSize:4,
                pageNum:1,
                total:0,


            }
        },
        methods:{
            async getDocumentList(){
                const temp=await getDocumentList({
                    userId:window.localStorage.getItem('userId'),
                    pageSize:this.pageSize,
                    pageNum:this.pageNum
                })
                this.documentList=temp.data.documentList
                console.log(temp.data)
                this.total=temp.data.userDocuments
            },
             viewDocumentDetail(documentId){
                 this.$router.push({path:'/personalCenter/documentDetail',query:{documentId:documentId}})
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.pageNum=val
                this.getDocumentList()
                console.log(`当前页: ${val}`);
            },

        },
        mounted() {
        this.getDocumentList()
        }
    }

</script>



<style scoped>
    .introduce{
        overflow:hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;

    }

</style>