import request from "./request";


export const getPostListByType = async ({userId, postTypeId, pageSize, pageNum}) => {
    return await request.get('/post-server/post/getPostListByType', {
        params: {
            userId,
            postTypeId,
            pageSize,
            pageNum
        },
    })
}

export const getAllPersonalPost = async ({userId, pageSize, pageNum}) => {
    return await request.get('/post-server/post/getAllPersonalPost', {
        params: {
            userId,
            pageSize,
            pageNum
        },
    })
}


export const deletePostByPostId = async ({userId, postId}) => {
    return await request.post('/post-server/post/deletePostByPostId', {
        data: {
            userId,
            postId
        },
    })
}
export const getBasePostInfo = async ({postId}) => {
    return await request.get('/post-server/post/getBasePostInfo', {
        params: {
            postId
        },
    })
}

export const modifyPost = async ({postId,
                                     postTitle,
                                     postUserName,
                                     postFirstLine,
                                     postSecondLine,
                                     postAppreaciation,
                                     postExplanation,
                                     postType,
                                 }) => {
    return await request.post('/post-server/post/modifyPost', {
        params: {
            postId,
            postTitle,
            postUserName,
            postFirstLine,
            postSecondLine,
            postAppreaciation,
            postExplanation,
            postType,
        },
    })
}

export const doComment = async ({postCommentContent,
                                    postCommentUserId,
                                    postCommentPostId
                                }) => {
    return await request.post('/post-server/post-comment/doComment', {
        params: {
            postCommentContent,
            postCommentUserId,
            postCommentPostId
        },
    })
}
export const getPostTypes = async () => {
    return await request.get('/post-server/post-type/getPostType')
}


export const getPostCommentList = async ({postId, pageNum, pageSize}) => {
    return await request.get('/post-server/post-comment/getPostCommentList', {
        params: {
            postId,
            pageNum,
            pageSize
        },
    })
}

export const getPostCollectionList = async ({userId, pageNum, pageSize}) => {
    return await request.get('/post-server/post-collection/getPostCollectionList', {
        params: {
            userId,
            pageNum,
            pageSize
        },
    })
}

export const getPostBrowseList = async ({userId, pageNum, pageSize}) => {
    return await request.get('/post-server/post-browse/getPostBrowseList', {
        params: {
            userId,
            pageNum,
            pageSize
        },
    })
}
export const deletePostBrowse = async ({userId, postId}) => {
    return await request.post('/post-server/post-browse/deletePostBrowse', {
        params: {
            userId,
            postId
        },
    })
}
//获取我的投稿列表
export const getMyManuscript = async ({userId}) => {
    return await request.get('/post-server/manuscript/getMyManuscript', {
        params: {
            userId
        },
    })
}
//获取比赛入围作品
export const getFinalist = async ({competitionId,pageNum,pageSize,inputKey}) => {
    return await request.get('/post-server/manuscript/getFinalist', {
        params: {
            competitionId,
            pageNum,
            pageSize,
            inputKey
        },
    })
}
//获取投稿详情
export const getManuscriptInfo = async ({manuscriptId}) => {
    return await request.get('/post-server/manuscript/getManuscriptInfo', {
        params: {
            manuscriptId
        },
    })
}

export const addPost = async ({postTitle,
                                  postFirstLine,
                                  postSecondLine,
                                  postAppreaciation,
                                  postExplanation,
                                  postUserId,
                                  postUserName,
                                  postTypeId,
                                }) => {
    return await request.post('/post-server/post/addPost', {
        params: {
            postTitle,
            postFirstLine,
            postSecondLine,
            postAppreaciation,
            postExplanation,
            postUserId,
            postUserName,
            postTypeId,
        },
    })
}