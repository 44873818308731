<template>
  <div style="display:flex;flex-direction:column;width:100%;">
    <span style="margin-left:20px;text-align:left;font-weight: bold;font-size: 16px;">对联课堂</span>
    <el-divider style="margin-top:0px;margin-bottom: 0px" border-style="double"
                content-position="left"/>
  </div>
  <div id="content">
    <div v-for="item in vedioList" :key="item" style="width:250px;height:150px;margin:11px">
      <img @click="toVedioDetial(item.coupletClassSrc,item.coupletClassId)" :src="item.coupletClassImage" :title="item.coupletClassTitle" style="width:100%;height:100%;"/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      vedioList:this.vedioListGet
    }
  },
  props: {
    vedioListGet: Array,
  },
  methods: {
    toVedioDetial(url,classId) {
      const { href } = this.$router.resolve({
        path: "/coupletClass",
        query: {vedio_url: "/mp_weChat/" + url.split("s/")[1], vedioId: classId}
      });
      window.open(href, '_blank');
    }
  },
};

</script>

<style scoped>
#content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.el-divider {
  background-color: #cccccc;
  height: 2px;
}
</style>
