import request from './request'

export const getCompetitionList = async ({
  pageNum,
  pageSize
}) => {
  return await request.get('/post-server/competition/getCompetitionList', {
    params: {
      pageNum,
      pageSize
    },
  })
}

export const getCompetitionInfo = async ({
  competitionId
}) => {
  return await request.get('/post-server/competition/getCompetitionInfo', {
    params: {
      competitionId
    },
  })
}

