<template>
    <div>
        <el-card shadow="hover" v-for="(item,i) in noticeList" :key="i"
                 style="margin-left: 5%;width:700px ">
            <div  style="width: 100%;height: 100px;">
                <div style="display: flex">
                    <div style="margin-left: 20px">
                        <el-tag   type="success">{{item.noticeTitle}}</el-tag >
                    </div>
                </div>
                <div style="text-align:left;margin-left: 50px;width: 600px;" class="introduce" >
                    {{item.noticeContent}}
                </div>
                <div style="margin-left: 250px;margin-top:25px;text-align: right;width: 400px">
                    <span>{{item.noticeTime}}</span><br/>&nbsp;
                </div>
            </div>
        </el-card>
    </div>
</template>


<script>

    import {getNoticeList} from '../../apis/user'

    export default {
        data(){
            return{
                noticeList:[],
                pageSize:4,
                pageNum:1,
                total:0,


            }
        },
        methods:{
            async getNoticeList(){
                const temp=await  getNoticeList({
                    userId:window.localStorage.getItem('userId'),
                    pageSize:this.pageSize,
                    pageNum:this.pageNum
                })
                this.noticeList=temp.data.noticeList
                this.total=temp.data.total
            }
        },
        mounted() {
                this.getNoticeList()

        }
    }

</script>



<style scoped>
    .introduce{
        overflow:hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;

    }

</style>