<template>
  <div style="display:flex;flex-direction:column;width:100%">
    <span style="margin-left:20px;text-align:left;font-weight: bold;font-size: 16px;">新闻频道</span>
    <el-divider style="margin-top:0px;" border-style="double" content-position="left"/>
  </div>
  <div id="content">
    <div class="content-outline">
      <div style="width:100%">
        <el-carousel :interval="5000" arrow="always" height="300px">
          <el-carousel-item v-for="item in newsPictureList" :key="item">
            <div style="width:100%;height:100%;background-color:white">
              <img :src="item.newsImage" style="width:100%;height:100%;"/>
              <h1 style="filter: blur(0);-webkit-filter: blur(0);font-size:16px;color:white;margin-top:-50px;width:90%;text-align:left;margin-left:20px">
                {{ item.newsTitle }}</h1>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="content-outline">
      <div style="width:90%;height:100%" class="newsTable">
        <div style="width:100%;height:40px;display:flex;flex-direction:row;margin-top:-16px;">
          <el-divider style="width:5%"/>
          <el-link :underline="false" style="font-size: 15px;margin:0 5px">联坛快讯</el-link>
          <el-divider style="max-width:82%"/>
        </div>
        <el-table
            :data="tableData"
            :cell-style="{'text-align':'left','back-ground':'unset'}"
            :row-style="{fontSize:'16px',color:'#606266',fontFamily:'Helvetica,Arial,sans-serif'}"
            style="width: 100%"
            :show-header="false"
            height="270px">
          <el-table-column prop="newsTitle" label="newsTitle">
            <template #default="scope">
              <el-link>{{ scope.row.newsTitle }}</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "home_news",
  data() {
    return {
      newsPictureList: this.newsPictureListGet,
      tableData: this.tableDataGet,
    }
  },
  props: {
    newsPictureListGet: Array,
    tableDataGet: Array
  },
}
</script>

<style scoped>
#content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.content-outline {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsTable /deep/ .el-table, .el-table__expanded-cell {
  background-color: transparent;
}

.newsTable /deep/ .el-table tr {
  background-color: transparent !important;
}

.newsTable /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {

  background-color: transparent !important;

}

.el-divider {
  background-color: #cccccc;
  height: 2px;
}
</style>
