import request from './request'


export const getUserInformation = async ({ userId }) => {
  return await request.get('/user-server/user/getUserInformation', {
    params: {
      userId
    },
  })
}
export const getAttentionList = async ({ userId, pageSize,pageNum}) => {
  return await request.get('/user-server/relationship/getAttentionList', {
    params: {
      userId,
      pageSize,
      pageNum
    },
  })
}
export const searchAttention = async ({ userId,inputKey, pageSize,pageNum}) => {
  return await request.get('/user-server/relationship/searchAttention', {
    params: {
      userId,
      inputKey,
      pageSize,
      pageNum
    },
  })
}
export const getFanList = async ({ userId, pageSize,pageNum}) => {
  return await request.get('/user-server/relationship/getFanList', {
    params: {
      userId,
      pageSize,
      pageNum
    },
  })
}
export const getNoticeList = async ({ userId, pageSize,pageNum}) => {
  return await request.get('/user-server/notice/getNoticeList', {
    params: {
      userId,
      pageSize,
      pageNum
    },
  })
}
//查询当前用户未读通知的条数
export const getNoticeNum = async ({ userId}) => {
  return await request.get('/user-server/notice/getNoticeNum', {
    params: {
      userId
    },
  })
}
//将所有未读通知标记为已读通知
export const setNoticeRead = async ({ userId}) => {
  return await request.get('/user-server/notice/setNoticeRead', {
    params: {
      userId
    },
  })
}

export const getRelationship = async ({ relationshipUser1,relationshipUser2}) => {
  return await request.get('/user-server/relationship/getRelations/'+relationshipUser1+'/'+relationshipUser2)
}
export const doAttention = async ({ userId,userId2}) => {
  return await request.post('/user-server/relationship/doAttention', {
    params: {
      userId,
      userId2
    },
  })
}
export const cancelAttention = async ({ userId,userId2}) => {
  return await request.post('/user-server/relationship/cancelAttention', {
    params: {
      userId,
      userId2
    },
  })
}