<template>
      <div id="page-title">
        <img src="../assets/images/competition_title.png" style="height:70%"/>
      </div>
      <div style="display:flex;flex-direction:column;width:100%;margin-top:10px">
        <span
            style="margin-left:20px;text-align:left;font-weight: bold;font-size: 16px;">联坛赛事</span>
        <el-divider style="margin-top:0px;" border-style="double" content-position="left"/>
      </div>
      <div
          style="display:flex;flex-direction:row;align-items:center;justify-content:center;width:100%;">
        <div style="display:flex;flex-direction:row;width:100%;flex-wrap: wrap;margin-bottom:10px;">
          <div v-for="item in dataList" :key="item" class="competition_item">
            <el-link @click="jumpToWithPath()" :underline="false" class="content_link"><p class="competition_content">
              {{ item.competitionName }}</p></el-link>
            <div style="width:100%;height:100%;">
              <img @click="jumpToWithPath()" :src="item.competitionImage" style="width:100%;height:140px;"/>
            </div>
            <div style="width:100%;margin-top:5px;">
              <el-button type="primary" style="width:95px;font-size:12px;border-radius:30px;">
                查看入围作品
              </el-button>
              <el-divider direction="vertical"/>
              <el-button type="success" v-if="timeCompare(item.competitionStartDate)"
                         style="width:95px;font-size:12px;border-radius:30px;">
                投稿我的作品
              </el-button>
              <el-button type="info" v-if="!timeCompare(item.competitionStartDate)"
                         style="width:95px;font-size:12px;border-radius:30px;">
                查看获奖作品
              </el-button>
            </div>
            <p class="competition_time">投稿截止时间：{{ item.levyEndDate }}</p>
          </div>
          <div class="competition_item" style="padding:0">
            <img src="../assets/images/competition_more.png"
                 style="border-radius:10px 10px 0 0;width:100%;height:210px"/>
            <p class="competition_more">暂未发布，敬请期待！</p>
          </div>
        </div>
      </div>
      <div
          style="box-shadow:0px 0px 15px 5px #cccccc;padding:30px;bottom:20px;height:60px;background-color:white;border-radius:50px;display:flex;flex-direction:column;align-items:center;justify-content: center">
        <el-pagination
            @current-change="handleCurrentChange"
            background
            layout="prev, pager, next,total"
            :total="competitionTotal"
            :page-size="10"
            class="pagination"
        />
      </div>
</template>

<script>
import {getCompetitionList} from "../apis/competitions";

export default {
  name: "CompetitionChannel",
  data() {
    return {
      competitionTotal: 0,
      dataList: [],
    }
  },
  methods: {
    async getCompetitionCount() {
      let res = await getCompetitionList({pageNum: 0, pageSize: 10})
      this.competitionTotal = res.data.total
      this.dataList = res.data.competitionList
      console.log(this.competitionTotal)
      console.log(this.dataList)
    },
    async getCompetitionList(pageNum, pageSize) {
      let res = await getCompetitionList({pageNum: pageNum, pageSize: pageSize})
      this.dataList = res.data.competitionList
      console.log(this.dataList)
    },
    handleCurrentChange(val) {
      console.log('当前页: ' + val);
      this.getCompetitionList(val, 10);
    },
    timeCompare(gettime) {
      const today = new Date();  //获取当前时间
      gettime = gettime.replace(/-/g, '/');
      //转化成时间戳作比较
      let endTime = new Date(gettime) //自己的时间
      return today.getTime() <= endTime.getTime();
    },
    jumpToWithPath() {
      this.$router.push('/competitionDetail')
    }
  },
  mounted() {
    this.getCompetitionCount()
  }
}
</script>

<style scoped>
#page-title {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/images/competiton_background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

.competition_item {
  width: 240px;
  height: 240px;
  margin: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: solid 1px #cccccc;
}

.competition_item:hover {
  box-shadow: 0px 0px 15px 5px #cccccc;
}

.competition_content {
  font-size: 17px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 210px;
  text-align: center;
  line-height: 30px;
  margin: 0;
  height: 30px;
}

.competition_time {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-align: left;
  line-height: 30px;
  margin: 0;
  height: 40px;
}

.competition_more {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-align: right;
  line-height: 30px;
  margin: 0;
  height: 30px;
}

.pagination /deep/ .el-pagination__total {
  margin-left: 18px;
}
.el-divider {
  background-color: #cccccc;
  height: 2px;
}
</style>
