<template>
      <div class="top-search-wrap">
        <div class="top-search">
          <div class="top-search-tip">中国楹联家</div>
          <div class="top-search-input">
            <el-input
                v-model="inputKey"
                size="large"
                placeholder="请输入搜索关键字"
                suffix-icon="Search"
            />
          </div>
        </div>
        <div class="good-master-list">
          <div v-if="masterList.length !== 0">
            <div
                v-for="master in masterList"
                :key="master.masterId"
                class="good-master-wrap"
            >
              <el-image
                  :src="master.masterPhoto"
                  fit="fill"
                  class="good-master-image"
              />
              <router-link
                  class="good-master-name"
                  :to="{
                path: '/masterDetail',
                query: {
                  userId: master.masterBindedUserId,
                },
              }"
              >{{ master.masterName }}
              </router-link
              >
            </div>
          </div>
        </div>
      </div>

      <div class="good-master">
        <div class="top-tip">优秀楹联家</div>
        <div class="good-master-list">
          <div
              v-for="master in goodMasterList"
              :key="master.masterId"
              class="good-master-wrap"
          >
            <el-image
                :src="master.masterPhoto"
                fit="fill"
                class="good-master-image"
            />
            <router-link
                class="good-master-name"
                :to="{
              path: '/masterDetail',
              query: {
                userId: master.masterId,
              },
            }"
            >{{ master.masterName }}
            </router-link
            >
          </div>
        </div>
      </div>

<!--      <div class="master-elegant">-->
<!--        <div class="top-tip">诗友风采</div>-->
<!--        <div class="master-elegant-list">-->
<!--          <div-->
<!--              v-for="master in masterElegantList"-->
<!--              :key="master.masterId"-->
<!--              class="master-elegant-wrap"-->
<!--          >-->
<!--            <el-image-->
<!--                :src="master.masterImage"-->
<!--                fit="fill"-->
<!--                class="master-elegant-image"-->
<!--            />-->
<!--            <router-link-->
<!--                class="master-elegant-name"-->
<!--                :to="{-->
<!--              path: '/masterDetail',-->
<!--              query: {-->
<!--                masterId: master.masterId,-->
<!--              },-->
<!--            }"-->
<!--            >{{ master.masterName }}-->
<!--            </router-link-->
<!--            >-->
<!--            <div class="productions">-->
<!--              <router-link-->
<!--                  v-for="production in master.productions"-->
<!--                  :key="production.coupletId"-->
<!--                  class="productions-item"-->
<!--                  :to="{-->
<!--                path: '/coupletDetail',-->
<!--                query: {-->
<!--                  coupletId: production.coupletId,-->
<!--                },-->
<!--              }"-->
<!--              >《{{ production.coupletTitle }}》-->
<!--              </router-link-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="new-master">
        <div class="top-tip">最新加入</div>
        <div class="new-master-list">
          <div
              v-for="master in newMasterList"
              :key="master.masterId"
              class="new-master-wrap"
          >
            <el-image
                :src="master.masterPhoto"
                fit="fill"
                class="new-master-image"
                style="border-radius: 50%"
            />
            <router-link
                class="new-master-name"
                :to="{
              path: '/masterDetail',
              query: {
                userId: master.masterBindedUserId,
              },
            }"
            >{{ master.masterName }}
            </router-link
            >
          </div>
        </div>
      </div>
</template>

<script>
import {getSomeMasterList, getNewAddMaster} from '../../apis/couplet'

export default {
  name: 'Master',
  data() {
    return {
      inputKey: "",
      masterList: [],
      goodMasterList: [],
      masterElegantList: [
        {
          masterId: "1",
          masterName: "周勇军",
          masterImage:
              "https://static.699pic.com/images/activeimg/62ddf96c66949.jpg!/both/317x216",
          productions: [
            {
              coupletId: "212",
              coupletTitle: "阿斯达卡死",
            },
            {
              coupletId: "213212",
              coupletTitle: "公开窘境",
            },
            {
              coupletId: "1123",
              coupletTitle: "从房间里撒",
            },
          ],
        },
        {
          masterId: "2",
          masterName: "周勇军",
          masterImage:
              "https://static.699pic.com/images/activeimg/62ddf96c66949.jpg!/both/317x216",
          productions: [
            {
              coupletId: "212",
              coupletTitle: "阿斯达dsadksakdksa卡死",
            },
            {
              coupletId: "213212",
              coupletTitle: "公开窘境",
            },
            {
              coupletId: "1123",
              coupletTitle: "从房间里撒",
            },
          ],
        },
      ],
      newMasterList: [],
    };
  },
  methods: {
    async getSomeMasterList() {
      const temp = await getSomeMasterList({
        num: 16
      })
      this.goodMasterList = temp.data.masterList
    },
    async getNewAddMaster() {
      const temp = await getNewAddMaster({
        num: 16
      })
      this.newMasterList = temp.data.masterList
    },
  },
  mounted() {
    this.getSomeMasterList()
    this.getNewAddMaster()
    console.log(this.masterList.length)
  }
};
</script>

<style scoped>
.top-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #bababa;
}

.top-search-tip {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 20px;
  color: black;
  font-weight: bold;
  margin-left: 20px;
  padding: 10px 0;
}
.top-search-wrap{
  width:100%;
}
.top-search-input {
  margin-right: 20px;
  padding: 10px 0;
}

.good-master{
  width:100%;
}
.master-elegant{
  width:100%;
}
.new-master {
  margin-top: 10px;
}

.top-tip {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 18px;
  color: black;
  font-weight: bold;
  text-align: left;
  padding: 10px 20px;
  border-bottom: 2px solid #dddddd;
  margin-bottom: 20px;
}

.good-master-list {
  width:100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction:row;
  align-items:center;
  justify-content:center;
}

.good-master-wrap {
  margin: 10px 20px;
}

.good-master-image {
  width: 120px;
  height: 160px;
}

.good-master-name {
  display: block;
}

.master-elegant-list {
  display: flex;
  padding: 0 60px;
  flex-wrap: wrap;
}

.master-elegant-wrap {
  margin: 10px 20px;
}

.master-elegant-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.master-elegant-name {
  display: block;
}

.productions {
  margin-top: 20px;
}

.productions-item {
  display: block;
  color: #1539b0;
}

.productions-item::before {
  content: "·";
  /* font-size: 20px; */
  font-weight: bold;
  margin-right: 2px;
}

.productions-item:active {
  color: #e93737;
}

.new-master-list {
  display: flex;
  padding: 0 60px;
  flex-wrap: wrap;
}

.new-master-wrap {
  margin: 10px 20px;
}

.new-master-image {
  width: 80px;
  height: 80px;
  border-radius: 0;
  overflow: hidden;
}

.new-master-name {
  display: block;
}
</style>
