<template>
    <div>
        <div style="height: 50px;display: flex;margin-left: 40px">
            <el-button @click="showCoupletMethod" >对联</el-button>
            <el-button @click="showPostMethod">帖子</el-button>
        </div>
        <div v-show="showCouplet">
            <!--        结果展示-->
            <div style="width: 80%;margin-left: 1%" class="main">
                <el-card shadow="hover" v-for="(message,index) in coupletMessages" :key="index"
                         style="margin-left: 5%;width:700px ">

                    <div style="height:140px;margin-left: 0">
                        <div style="display:inline-block;margin-left: 0%;width: 80%">
                            <p class="message" style="font-weight:bold" @click="switchTo">{{message.coupletTitle}}</p>
                            {{message.coupletAuthorName}} <br/>
                            <p style="font-weight: lighter" class="message">
                                {{message.coupletFirstLine}}<br/>
                                {{message.coupletSecondLine}}
                            </p>
                            <p >&nbsp;收藏时间:&nbsp;{{message.coupletCollectionTime}}</p>
                        </div>

                        <div  style="width:18%;height: 100%;display:inline-block;">
                            <div style="display: inline-block;width: 50%;margin-top: 20%;margin-left: 24%">
                                <el-button type="primary" round style="height: 50%;width: 100%;display: inline-block" @click="viewCouplets(message.coupletId)">
                                    <el-icon :size="20">
                                        <Edit />
                                    </el-icon>
                                </el-button>
                                <br><br>
                            </div>
                        </div>
                    </div>
                    <br>
                </el-card>
            </div>
            <br>
            <div class="footer" style="margin: 0 auto;width: 100%;">
                <div class="block">
                    <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCoupletCurrentChange"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total=total>
                    </el-pagination>
                </div>
            </div>
        </div>
        <div v-show="showPost">
            <!--        结果展示-->
            <div style="width: 80%;margin-left: 1%" class="main">
                <el-card shadow="hover" v-for="(message,index) in postMessages" :key="index"
                         style="margin-left: 5%;width:700px ">

                    <div style="height:100px;margin-left: 0">
                        <div style="display:inline-block;margin-left: 0%;width: 80%">
                            <p class="message" style="font-weight:bold">{{message.postTitle}}</p>
                            {{message.postUserName}} &nbsp;&nbsp;{{message.postType}}<br/>
                            <p style="font-weight: lighter" class="message">
                                {{message.postFirstLine}}<br/>
                                {{message.postSecondLine}}
                            </p>
                            <p >&nbsp;收藏时间:&nbsp;{{message.postCollectionTime}}</p>
                        </div>
                        <div  style="width:18%;height: 100%;display:inline-block;">
                            <div style="display: inline-block;width: 50%;margin-top: 20%;margin-left: 24%">
                                <el-button type="primary" round style="height: 50%;width: 100%;display: inline-block" @click="viewPost(message.postId)">
                                    <el-icon :size="20">
                                        <Edit />
                                    </el-icon>
                                </el-button>
                                <br><br>
                            </div>
                        </div>
                    </div>
                    <br>
                </el-card>
            </div>
            <br>
            <div class="footer" style="margin: 0 auto;width: 100%;">
                <div class="block">
                    <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handlePostCurrentChange"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total=total>
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {getCoupletCollectionList} from "../../apis/couplet";
    import {getPostCollectionList} from "../../apis/post";

    export default {
        data() {
            return {
                showCouplet:true,
                showPost:false,
                total: 5,
                pageSize:3,
                pageNum: 1,
                coupletMessages: [],
                postMessages: [],
                coupletTypeList: [],

            }
        },
    methods:{
        async getCoupletCollectionList(){
            const temp1 = await getCoupletCollectionList(
                {userId: window.localStorage.getItem('userId'),
                    pageSize:this.pageSize,
                    pageNum:this.pageNum
                })
            this.coupletMessages=temp1.data.coupletList
            this.total=temp1.data.userCoupletCollections
        },
        async getPostCollectionList(){
            const temp1 = await getPostCollectionList(
                {userId: window.localStorage.getItem('userId'),
                    pageSize:this.pageSize,
                    pageNum:this.pageNum
                })
            this.postMessages=temp1.data.postList
            this.total=temp1.data.userPostCollections
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCoupletCurrentChange(val) {
            this.pageNum=val
            this.getCoupletCollectionList()
            console.log(`当前页: ${val}`);
        },
        handlePostCurrentChange(val) {
            this.pageNum=val
            this.getPostCollectionList()
            console.log(`当前页: ${val}`);
        },
        showCoupletMethod(){
            this.showCouplet=true
            this.showPost=false
            this.getCoupletCollectionList()
        },
        showPostMethod(){
            this.showCouplet=false
            this.showPost=true
            this.getPostCollectionList()
        },
        viewCouplets(coupletId){
            this.$router.push({path:'/personalCenter/getCoupletDetailInfo',query:{coupletId:coupletId}})
        },
        viewPost(postId){
            this.$router.push({path:'/personalCenter/modificationPost',query:{postId:postId}})
        },
    },
        mounted() {
            this.getCoupletCollectionList()
            this.getPostCollectionList()
        }
    }

</script>


<style scoped>


</style>