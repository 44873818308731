<template>
      <div id="page-title">
        <img src="../../assets/images/competition_title.png" style="height:70%"/>
      </div>
      <div style="display:flex;flex-direction:column;width:100%;margin-top:10px">
      </div>
</template>

<script>
export default {
  data() {
    return {
      str: '# 标题'
    }
  },
}
</script>
<style scoped>
#page-title {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/images/competiton_background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
</style>
