<template>
    <div>
        <div>
            <div style="height: 50px;display: flex;margin-left: 40px">
                <el-button @click="showCoupletMethod" >对联</el-button>
                <el-button @click="showPostMethod">帖子</el-button>
                <el-button @click="showDocumentMethod">资料</el-button>
            </div>
            <div v-show="showCouplet">
                <!--        结果展示-->
                <div style="width: 80%;margin-left: 1%" class="main">
                    <el-card shadow="hover" v-for="(message,index) in coupletMessages" :key="index"
                             style="margin-left: 5%;width:700px ">

                        <div style="height:140px;margin-left: 0">
                            <div style="display:inline-block;margin-left: 0%;width: 80%">
                                <p class="message" style="font-weight:bold" @click="switchTo">{{message.coupletTitle}}</p>
                                {{message.coupletAuthorName}} <br/>
                                <p style="font-weight: lighter" class="message">
                                    {{message.coupletFirstLine}}<br/>
                                    {{message.coupletSecondLine}}
                                </p>
                                <p >&nbsp;收藏时间:&nbsp;{{message.coupletCollectionTime}}</p>
                            </div>

                            <div  style="width:18%;height: 100%;display:inline-block;">
                                <div style="display: inline-block;width: 50%;margin-top: 20%;margin-left: 24%">
                                    <el-tooltip class="item" effect="dark" content="查看详情" placement="top-start">
                                    <el-button type="primary" round style="height: 50%;width: 100%;display: inline-block" @click="viewCouplets(message.coupletId)">
                                        <el-icon :size="20">
                                            <Edit />
                                        </el-icon>
                                    </el-button>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" content="删除这条记录" placement="top-start">
                                    <el-button type="danger" round style="height: 50%;width: 100%;display: inline-block;margin-left: 0;margin-top: 5px" @click="deleteCoupletBrowse(message.coupletId)">
                                       <el-icon>
                                            <Delete />
                                        </el-icon>
                                    </el-button>
                                    </el-tooltip>
                                    <br><br>
                                </div>
                            </div>
                        </div>
                        <br>
                    </el-card>
                </div>
                <br>
                <div class="footer" style="margin: 0 auto;width: 100%;">
                    <div class="block">
                        <el-pagination
                                background
                                @size-change="handleSizeChange"
                                @current-change="handleCoupletCurrentChange"
                                :page-size="pageSize"
                                layout="total, prev, pager, next, jumper"
                                :total=total>
                        </el-pagination>
                    </div>
                </div>
            </div>
            <div v-show="showPost">
                <!--        结果展示-->
                <div style="width: 80%;margin-left: 1%" class="main">
                    <el-card shadow="hover" v-for="(message,index) in postMessages" :key="index"
                             style="margin-left: 5%;width:700px ">

                        <div style="height:100px;margin-left: 0">
                            <div style="display:inline-block;margin-left: 0%;width: 80%">
                                <p class="message" style="font-weight:bold">{{message.postTitle}}</p>
                                {{message.postUserName}} &nbsp;&nbsp;{{message.postType}}<br/>
                                <p style="font-weight: lighter" class="message">
                                    {{message.postFirstLine}}<br/>
                                    {{message.postSecondLine}}
                                </p>
                                <p >&nbsp;收藏时间:&nbsp;{{message.postCollectionTime}}</p>
                            </div>
                            <div  style="width:18%;height: 100%;display:inline-block;">
                                <div style="display: inline-block;width: 50%;margin-top: 20%;margin-left: 24%">
                                    <el-tooltip class="item" effect="dark" content="删除这条记录" placement="top-start">
                                    <el-button type="danger" round style="height: 50%;width: 100%;display: inline-block" @click="deletePostBrowse(message.postId)">
                                        <el-icon :size="20">
                                            <Delete />
                                        </el-icon>
                                    </el-button>
                                    </el-tooltip>
                                    <br><br>
                                </div>
                            </div>
                        </div>
                        <br>
                    </el-card>
                </div>
                <br>
                <div class="footer" style="margin: 0 auto;width: 100%;">
                    <div class="block">
                        <el-pagination
                                background
                                @size-change="handleSizeChange"
                                @current-change="handlePostCurrentChange"
                                :page-size="pageSize"
                                layout="total, prev, pager, next, jumper"
                                :total=total>
                        </el-pagination>
                    </div>
                </div>
            </div>
            <div v-show="showDocument">
                <!--        结果展示-->
                <div style="width: 80%;margin-left: 1%" class="main">
                    <el-card shadow="hover" v-for="(item,index) in documentMessages" :key="index"
                             style="margin-left: 5%;width:700px ">

                        <div  style="width: 100%;height: 100px;">
                            <div style="display: flex">
                                <div style="margin-left: 20px">
                                    <el-avatar :size="40" :src="item.documentPicture"></el-avatar>
                                </div>
                                <div style="margin-left: 10px;text-align: left;width: 400px">
                                    <span>{{item.documentName}}</span><br/>
                                    分数:<span>{{item.documentScore}}</span> &nbsp;
                                    收藏时间:<span>{{item.documentBrowseTime}}</span><br/>
                                </div>
                                <el-button type="primary" style="margin-left: 50px" @click="viewDocumentDetail(item.documentId)">查看详情</el-button>
                                <el-button type="danger" style="margin-left: 50px" @click="deleteDocumentBrowse(item.documentId)">删除记录</el-button>
                            </div>
                            <div style="text-align:left;margin-left: 50px" class="introduce">
                                {{item.documentIntroduction}}
                            </div>

                        </div>
                        <br>
                    </el-card>
                </div>
                <br>
                <div class="footer" style="margin: 0 auto;width: 100%;">
                    <div class="block">
                        <el-pagination
                                background
                                @size-change="handleSizeChange"
                                @current-change="handleDocumentCurrentChange"
                                :page-size="pageSize"
                                layout="total, prev, pager, next, jumper"
                                :total=total>
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import {getDocumentBrowseList,deleteDocumentBrowse} from '../../apis/file'
    import {getCoupletBrowseList,deleteCoupletBrowse} from '../../apis/couplet'
    import {getPostBrowseList,deletePostBrowse} from '../../apis/post'
    export default {
        data(){
            return{
                showCouplet:true,
                showPost:false,
                showDocument:false,
                total: 5,
                pageSize:3,
                pageNum: 1,
                coupletMessages: [],
                postMessages: [],
                documentMessages:[],
                coupletTypeList: [],
            }
        },
        methods:{
            async getDocumentBrowseList(){
                const temp=await getDocumentBrowseList({
                    userId:window.localStorage.getItem('userId'),
                    pageNum:this.pageNum,
                    pageSize:this.pageSize
                })
                this.documentMessages=temp.data.userDocumentList
                this.total=temp.data.userDocumentBrowses
            },
            async getCoupletBrowseList(){
                const temp=await getCoupletBrowseList({
                    userId:window.localStorage.getItem('userId'),
                    pageNum:this.pageNum,
                    pageSize:this.pageSize
                })
                this.coupletMessages=temp.data.coupletList
                this.total=temp.data.userCoupletBrowses
            },
            async getPostBrowseList(){
                const temp=await getPostBrowseList({
                    userId:window.localStorage.getItem('userId'),
                    pageNum:this.pageNum,
                    pageSize:this.pageSize
                })
                this.postMessages=temp.data.postList
                this.total=temp.data.userPostBrowses
            },
            async deletePostBrowse(postId){
                const temp=await deletePostBrowse({
                    userId:window.localStorage.getItem('userId'),
                    postId:postId
                })
                console.log(temp)
                this.getPostBrowseList()
            },
            async deleteCoupletBrowse(coupletId){

                const temp=await deleteCoupletBrowse({
                    userId:window.localStorage.getItem('userId'),
                    coupletId:coupletId
                })
                console.log(temp)
                this.getCoupletBrowseList()
            },
            async deleteDocumentBrowse(documentId){
                console.log(documentId)
                const temp=await deleteDocumentBrowse({
                    userId:window.localStorage.getItem('userId'),
                    documentId:documentId
                })
                console.log(temp)
                this.getDocumentBrowseList()
            },
            viewCouplets(coupletId){
                this.$router.push({path:'/personalCenter/getCoupletDetailInfo',query:{coupletId:coupletId}})
            },
            viewDocumentDetail(documentId){
                this.$router.push({path:'/personalCenter/documentDetail',query:{documentId:documentId}})
            },
            showCoupletMethod(){
                this.showCouplet=true
                this.showPost=false
                this.showDocument=false
                this.getCoupletBrowseList()
            },
            showPostMethod(){
                this.showCouplet=false
                this.showPost=true
                this.showDocument=false
                this.getPostBrowseList()
            },
            showDocumentMethod(){
                this.showCouplet=false
                this.showPost=false
                this.showDocument=true
                this.getDocumentBrowseList()
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCoupletCurrentChange(val) {
                this.pageNum=val
                this.getCoupletBrowseList()
                console.log(`当前页: ${val}`);
            },
            handlePostCurrentChange(val) {
                this.pageNum=val
                this.getPostBrowseList()
                console.log(`当前页: ${val}`);
            },
            handleDocumentCurrentChange(val) {
                this.pageNum=val
                this.getDocumentBrowseList()
                console.log(`当前页: ${val}`);
            },
        },
        mounted() {
            this.getCoupletBrowseList()
        }
    }

</script>



<style scoped>


</style>