<template>

  <div class="display-header">
    <div style="width: 100%;margin:20px 10px;">
      <div
          style="width: 100%;display:flex;flex-direction:column;align-items:center;justify-content: center">
        <div style="display:flex;flex-direction:row;margin-bottom:10px;">
          <h3 style="margin-right: 10px">关键字</h3>
          <a-input v-model:value="content" style="width: 300px"/>
          <a-button style="margin-left: 10px;background-color: #cdd3e0" @click="getChose()">检索
          </a-button>
        </div>
      </div>
      <div
          style="margin-left:40px;width: 100%;display:flex;flex-direction:column;align-items:flex-start;">
        <a-radio-group v-model:value="formState.resource" style="width:235px">
          <a-radio value="1">精准匹配</a-radio>
          <a-radio value="2">只显示相关诗句</a-radio>
        </a-radio-group>
      </div>
      <div
          style="margin-top:10px;width:100%;display:flex;flex-direction:column;align-items:flex-start;">
        <div
            style="margin:5px 5px;display: flex;flex-direction: row;align-items:center;justify-content: center">
          <p style="width: 50px;margin:0">朝代:</p>
          <el-select
              placeholder="请选择"
              v-model="chooseDynasty"
              style="padding: 0 0;width: 80px"
              size="small">
            <el-option
                v-for="item in formState.dynasty"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                style="text-align: center">
            </el-option>
          </el-select>
        </div>
        <div
            style="margin:5px 5px;display: flex;flex-direction: row;align-items:center;justify-content: center">
          <p style="width: 50px;margin:0">类型:</p>
          <el-select
              placeholder="请选择"
              v-model="chooseclassification"
              style="padding: 0 0;width: 80px"
              size="small">
            <el-option
                v-for="item in formState.classification"
                :key="item.coupletTypeId"
                :label="item.coupletTypeDetail"
                :value="item.coupletTypeId"
                style="text-align: center">
            </el-option>
          </el-select>
        </div>
        <div
            style="margin:5px 5px;display: flex;flex-direction: row;align-items:center;justify-content: center">
          <p style="width: 50px;margin:0">出处:</p>
          <el-select
              placeholder="请选择"
              v-model="chooseprovenance"
              style="padding: 0 0;width: 80px"
              size="small">
            <el-option
                v-for="item in formState.provenance"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
  <div style="display:flex;flex-direction:column;width:100%">
    <span style="margin-left:20px;text-align:left;font-weight: bold;font-size: 16px;">佳作赏析</span>
    <el-divider style="margin-top:0px;" border-style="double" content-position="left"/>
  </div>
  <div class="display">
    <div v-for="item in 6" :key="item" class="couplet-huge">
      <div class="couplet-display">
        <h2 style="width:100%;">己亥杂诗</h2>
        <h3 style="width:100%;margin:0">九州生气恃风雷</h3>
        <h3 style="width:100%;margin:0">万马齐喑究可哀</h3>
        <div style="width:90%;display:flex;flex-direction:row;justify-content: flex-end">
          <el-button style="color:green;border-radius:100%">赏</el-button>
          <el-button style="border-radius:100%">注</el-button>
          <el-button style="color:#409EFF;border-radius:100%;">评</el-button>
        </div>
      </div>
      <div class="couplet-details" v-if="isDisplay">

      </div>
    </div>
  </div>
</template>


<script>

import {getCoupletListByCoupletType} from '../../apis/search'
// import {StarOutlined, LikeOutlined, MessageOutlined} from '@ant-design/icons-vue';
import {getCoupletType, getSomeCoupletList} from '../../apis/couplet'

export default {

  data() {
    return {
      content: '',
      chooseDynasty: '不限',
      chooseclassification: '不限',
      chooseprovenance: '不限',
      name: '',
      isDisplay: false,
      formState: {
        resource: '',
        dynasty: [{
          value: 0,
          label: '不限'
        },
          {
            value: 1,
            label: '当代'
          }, {
            value: 2,
            label: '古代'
          },],
        classification: [],
        provenance: [{
          value: 0,
          label: '不限'
        },
          {
            value: 1,
            label: '当代'
          },
        ]
      },
      coupletList: [],
      recomendCoupletList: [],
      type1: 'StarOutlined',
      type2: 'LikeOutlined',
      type3: 'MessageOutlined',
      labelCol: {style: {width: '150px'}},
      wrapperCol: {span: 14},
      options1: [{
        value: 1,
        label: '任意'
      }, {
        value: 2,
        label: '题目'
      }, {
        value: 3,
        label: '上联'
      }, {
        value: 4,
        label: '下联'
      }],
      value1: '',
      //第几个字
      index: null,
      pageNum: 1,
      pageSize: 5,
      total: 0,
    }

  },
  methods: {
    async getChose() {
      console.log(this.index)
      this.isDisplay = !this.isDisplay
      const temp1 = await getCoupletListByCoupletType(
          {
            userId: 'oJCKH4n9mTgSE5iUKOV1_hziV-hM',
            coupletTypeId: 1,
            pageSize: 3,
            pageNum: 1
          })
      this.coupletList = temp1.data.coupletList
    },
    async getCoupletType() {
      const temp1 = await getCoupletType()
      this.formState.classification = temp1.data.coupletTypeList
    },
    async getSomeCoupletList() {
      const temp1 = await getSomeCoupletList({num: 3})
      this.recomendCoupletList = temp1.data.coupletList
    },
    handleChangeDynasty(value) {
      this.chooseDynasty = value
    },
    handleChangeClassification(value) {
      this.chooseclassification = value
    },
    handleChangeProvenance(value) {
      this.chooseprovenance = value
    }
  },
  mounted() {
    this.getCoupletType()
    this.getSomeCoupletList()
  }
}
</script>

<style scoped>

.display {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}

.select-first .el-select .el-input {
  border-color: #409EFF;
  width: 50px;
}

.display-header {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  background-image: url("../../assets/images/competiton_background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

.couplet-display {
  padding:10px;
  width: 100%;
  height: 150px;
  margin: 5px 1%;
  background-image: url("../../assets/images/display.jpeg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.couplet-huge {
  width: 48%;
  margin: 5px 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.couplet-details {
  width: 100%;
  background-image: url("../../assets/images/display.jpeg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  height: 50px;
  margin:0;
}

.couplet-display:hover {
  box-shadow: 0px 0px 15px 5px #cccccc;
}
</style>
