<template>
      <div class="master-information">
        <div>
          <el-image :src="formData.userPortrait" fit="fill" class="master-image"/>
        </div>
        <div class="master-info">
          <div class="master-name">
            姓名：<span class="name-span">{{ formData.userName }}</span>
          </div>
          <div>个人简介：{{ formData.userIntroduction }}</div>
        </div>
      </div>

      <div style="width: 100%;margin-top: 10px;border-bottom: 2px solid #dddddd;">
        <div class="top-back">
          <el-button @click="goBack">返回</el-button>
        </div>
      </div>
      <div style="width:100%;border-bottom: 2px solid #dddddd;display:flex;flex-direction:column;align-items:center;justify-content: center">
        <div class="production-line">
          <div class="production">
            <div class="title">{{ post.postTitle }}</div>
            <div class="info">
              <div>
                类型<span class="info-span">{{ post.postType }}</span>
              </div>
            </div>
            <div class="first-line">{{ post.postFirstLine }}</div>
            <div class="second-line">{{ post.postSecondLine }}</div>
          </div>
        </div>
        <div style="width: 100%;margin-left: 200px;text-align: left;">
          <div> 注释： {{ post.postExplanation }}</div>
          <div> 赏析： {{ post.postAppreaciation }}</div>
        </div>
      </div>
      <br/>
      <!--        评论区展示-->

      <div class="my-reply" style="width: 100%">
        <div style="width:100%;display: flex; flex-direction: row;align-items: center;justify-content: center">
          <div style="width:600px;">
            <el-input placeholder="快来发表你的评论吧！" v-model="textarea"></el-input>
          </div>
          <el-button size="medium" type="primary" style="margin-left: 20px;" @click="doComment">
            发表评论
          </el-button>
        </div>
        <br/>
        <div>
          <div v-for="(item,i) in postCommentList" :key="i" style="width: 100%;height: 100px;">
            <div style="display: flex">
              <div style="margin-left: 100px">
                <el-avatar :size="40" :src="item.userPortrait"></el-avatar>
              </div>
              <div style="margin-left: 10px;text-align: left">
                <span>{{ item.userNickName }}</span><br/>
                <span>{{ item.postCommentTime }}</span>
              </div>
            </div>
            <div style="text-align:left;margin-left: 150px">
              {{ item.postCommentContent }}
            </div>
          </div>
          <br>
          <div class="footer" style="margin-left: 500px;width: 100%;">
            <div class="block">
              <el-pagination
                  background
                  @current-change="handleCurrentChange"
                  :page-size="pageSize"
                  layout="total, prev, pager, next, jumper"
                  :total=total>
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import {getUserInformation} from "../../apis/user"
import {getBasePostInfo, getPostCommentList, doComment} from "../../apis/post";
export default {
  name: "MasterDetail",

  data() {
    return {
      userId: this.$route.query.userId,
      formData: {},
      post: {
        postId: '',
        postTitle: '',
        postUserName: '',
        postFirstLine: '',
        postSecondLine: '',
        postAppreaciation: '',
        postExplanation: '',
        postType: '',
      },
      //评论相关参数
      textarea: '',
      postCommentList: [],
      pageNum: 1,
      pageSize: 5,
      total: 0,
    }
  },
  methods: {
    async getUserInformation() {
      const temp = await getUserInformation({
        userId: this.userId
      })
      this.formData = temp.data.formData
    },
    async getBasePostInfo() {
      const temp = await getBasePostInfo({postId: this.$route.query.postId});
      this.post = temp.data.formData
      if (this.post.postExplanation === '') {
        this.post.postExplanation = '暂无'
      }
      if (this.post.postAppreaciation === '') {
        this.post.postAppreaciation = '暂无'
      }
    },
    async getPostCommentList() {
      const temp = await getPostCommentList({
        postId: this.$route.query.postId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })
      this.postCommentList = temp.data.postCommentList
      this.total = temp.data.total
    },
    async doComment() {
      const temp = await doComment({
        postCommentPostId: this.$route.query.postId,
        postCommentUserId: window.localStorage.getItem('userId'),
        postCommentContent: this.textarea
      });
      this.getPostCommentList()
      console.log(temp)
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getPostCommentList()
    },
    goBack() {
      this.$router.go(-1)
    }
  },
  mounted() {
    this.getUserInformation()
    this.getBasePostInfo()
    this.getPostCommentList()
  }
}

</script>

<style scoped>
#content {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top-back {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 18px;
  color: black;
  font-weight: bold;
  text-align: left;
  padding: 10px 20px;
  margin-bottom: 2px;
  margin-left: 1280px;
}

.master-information {
  margin: 10px 10px;
  padding: 10px 10px;
  background-color: #e7e7e7;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name-span {
  font-size: 20px;
  font-weight: bold;
}

.master-name {
  margin-bottom: 8px;
}

.master-info {
  text-align: left;
  margin: 0 20px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: left;
  overflow: hidden;
}

.master-image {
  width: 200px;
  height: 200px;
  border-radius: 20px;
  overflow: hidden;
}

.production-line {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-size: 16px;
  width: 100%;
  background-color: #cccccc;
}

.info {
  display: flex;
  font-size: 10px;
  margin: 4px 0;
  justify-content: center;
  align-items: center;
}

.info-span {
  color: purple;
  padding-left: 4px;
  padding-right: 8px;
}

.first-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.second-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
</style>
