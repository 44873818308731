<template>
    <div>
        <el-card shadow="hover"  style="margin-left: 5%;width:700px ">

            <div  style="width: 100%;height: 150px;">
                <div style="display: flex">
                    <div style="margin-left: 20px">
                        <el-avatar :size="40" :src="documentDetail.documentPicture"></el-avatar>
                    </div>
                    <div style="margin-left: 10px;text-align: left;width: 400px">
                        <span>{{documentDetail.documentName}}</span><br/>
                        分数:<span>{{documentDetail.documentScore}}</span> &nbsp;
                    </div>
                    <el-button type="primary"  @click="viewDocumentDetail(documentDetail.documentId)">移除书架</el-button>
                    <el-button type="success"  @click="readDocument(documentDetail.documentId)">阅读书籍</el-button>
                </div>
                <div style="text-align:left;margin-left: 50px" class="introduce">
                    {{documentDetail.documentIntroduction}}
                </div>

            </div>
        </el-card>
        <br/>
        <!--        评论区展示-->

        <div class="my-reply" style="width: 100%">
            <div style="display: flex; ">
                <div style="width:600px;margin-left: 60px;margin-right: 20px;">
                    <el-input placeholder="快来发表你的评论吧！" v-model="textarea"></el-input>
                </div>
                <el-button size="medium" type="primary" style="margin-left: 20px;"  @click="doComment">发表评论</el-button>
            </div>
            <br/>
            <div>
                <div v-for="(item,i) in documentCommentList" :key="i" style="width: 100%;height: 100px;">
                    <div style="display: flex">
                        <div style="margin-left: 100px">
                            <el-avatar :size="40" :src="item.documentCommentUserPortrait"></el-avatar>
                        </div>
                        <div style="margin-left: 10px;text-align: left">
                            <span>{{item.documentCommentUserNickName}}</span><br/>
                            <span>{{item.documentCommentTime}}</span>
                        </div>
                    </div>
                    <div style="text-align:left;margin-left: 150px">
                        {{item.documentCommentContent}}
                    </div>
                </div>
                <br>
                <div class="footer" style="margin: 0 auto;width: 100%;">
                    <div class="block">
                        <el-pagination
                                background
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :page-size="pageSize"
                                layout="total, prev, pager, next, jumper"
                                :total=total>
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>

    import {getDocumentDetail,getDocumentComment,readDocument} from "../../apis/file";

    export default {
        data(){
            return{
                documentDetail:[],
                pageSize:4,
                pageNum:1,
                total:0,
                isCollection:true,
                filePath:'',

                //评论相关
                textarea:'',
                documentCommentList:[],

            }
        },
        methods:{
            async getDocumentDetail(){
                const temp=await getDocumentDetail({
                    userId:window.localStorage.getItem('userId'),
                    documentId:this.$route.query.documentId
                })
                this.documentDetail=temp.data.documentInfo
                this.isCollection=temp.data.isCollection
                console.log(temp.data)
            },
            async getDocumentComment(){
                const temp=await getDocumentComment({
                    documentId:this.$route.query.documentId,
                    pageSize:this.pageSize,
                    pageNum:this.pageNum
                })
                this.documentCommentList=temp.data.documentCommentList
                this.total=temp.data.total
                console.log(temp.data)
            },
            async readDocument(documentId){
                const temp=await readDocument({
                    documentId:documentId
                })
                this.filePath=temp.data.filePath
                console.log(temp.data)
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.pageNum=val
                this.getDocumentComment()
                console.log(`当前页: ${val}`);
            },

        },
        mounted() {
            this.getDocumentDetail()
            this.getDocumentComment()
        }


    }

</script>


<style scoped>
    .introduce{
        overflow:hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
        display: -webkit-box;
        -webkit-box-orient: vertical;

    }
</style>