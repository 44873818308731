<template>
  <div id="login-page">
    <div id="login-box" v-if="!login_code_judgement">
      <div id="login-box-header">
        <div id="login-type">
          <el-link :underline="false" @click="login_type_passwd" style="font-size: 17px;font-weight: bold">账号密码登录
          </el-link>
          <el-divider direction="vertical" style="height:20px"/>
          <el-link :underline="false" @click="login_type_code" style="font-size: 17px;font-weight: bold">短信验证码登录
          </el-link>
        </div>
        <div id="qr-area">
          <div id="qr-code" @click="login_code_judgement_change"/>
        </div>
      </div>
      <div id="input-area">
        <div id="inputs-passwd" v-show="type_passwd">
          <el-input class="user-input" v-model="accountData.phoneNumber" style="margin-bottom:20px;"
                    placeholder="请输入手机号码" size="large" clearable/>
          <el-input
              v-model="accountData.password"
              type="password"
              class="user-input"
              placeholder="请输入密码"
              show-password
              size="large"
              id="password-input"
              style="border-radius:30px;outline:None"
          />
        </div>
        <div id="inputs-code" v-show="type_code">
          <el-input class="user-input" v-model="input" style="margin-bottom:20px;"
                    placeholder="请输入手机号码" size="large" clearable/>
          <el-input class="user-input" v-model="input"
                    placeholder="请输入手机验证码" size="large" clearable>
            <template #append>
              <el-button v-if="disabled" @click="verification">获取验证码</el-button>
              <el-button v-if="!disabled" disabled="true">重新获取({{ timer }}秒)</el-button>
            </template>
          </el-input>
        </div>
        <div id="login_box_bottom">
          <el-button link style="width:20%;margin-right:40%">忘记密码</el-button>
          <el-button link type="primary" style="width:20%">点我注册</el-button>
        </div>
        <el-button type="primary" style="width:320px;height:35px;margin-top:20px;font-size:17px;font-weight: bold">登 录</el-button>
      </div>
    </div>
    <div id="login-box-code" v-if="login_code_judgement">
      <div id="qr-box-header">
        <div id="qr-title">
            <p @click="login_type_passwd" style="font-size: 18px;font-weight: bold">请使用手机微信扫码登录
            </p>
        </div>
        <div id="computer-area">
          <div id="computer-change" @click="login_conputer_judgement_change"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    let type_passwd = true;
    let type_code = false;
    let disabled = true;
    let login_code_judgement = false;
    let accountData = {
      phoneNumber:"",
      password:""
    }
    return {
      type_passwd,
      type_code,
      disabled,
      timer: 60,
      accountData,
      login_code_judgement
    }
  },
  methods: {
    login_type_passwd() {
      this.type_passwd = true;
      this.type_code = false;
    },
    login_type_code() {
      this.type_passwd = false;
      this.type_code = true;
    },
    login_code_judgement_change() {
      this.login_code_judgement = true;
    },
    login_conputer_judgement_change() {
      this.login_code_judgement = false;
    },
    verification() {
      this.disabled = false;
      const authTimer = setInterval(() => {
        this.timer--
        if (this.timer <= 0) {
          this.disabled = true
          this.timer = 60
          clearInterval(authTimer)
        }
      }, 1000)
    }
  },
}
</script>

<style>
#login-page {
  height: 100vh;
  width: 100vw;
  background-image: url("../assets/images/login_back.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#login-box {
  width: 420px;
  height: 360px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5%;
  box-shadow: 0px 0px 50px 10PX lightsteelblue;
}
#login-box-code{
  width: 420px;
  height: 360px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5%;
  box-shadow: 0px 0px 50px 10PX lightsteelblue;
}
#login-box-header {
  display: flex;
  flex-direction: row;
  height: 30%;
}
#qr-box-header {
  display: flex;
  flex-direction: row;
  height: 30%;
}
#login_box_bottom {
  margin-top: 20px;
  width: 100%;
}

#input-area {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#inputs-passwd {
  width: 330px;
}

#inputs-code {

  width: 330px;
}

#login-type {
  margin-top: 40px;
  margin-right: 11%;
  width: 70%;
}
#qr-title{
  margin-top: 40px;
  margin-right: 11%;
  width: 70%;
}
#qr-area {
  margin: 0;
  width: 14%;
  height: 50%;
}
#computer-area{
  margin: 0;
  width: 14%;
  height: 50%;
}
#qr-code {
  margin-top: 25px;
  background-image: url("../assets/images/qr-code.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  width: 100%;
  height: 100%;
}
#qr-code:hover {
  background-image: url("../assets/images/qr-code-hover.png");
}
#computer-change{
  margin-top: 25px;
  background-image: url("../assets/images/computer_grey.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  width: 100%;
  height: 100%;
}
#computer-change:hover {
  background-image: url("../assets/images/computer.png");
}
</style>
