import {createRouter, createWebHashHistory} from 'vue-router'
import Login from '../views/Login.vue'
import CoupletSearch from '../components/CoupletsLibrary/CoupletSearch'
// import Header from '../components/Header.vue'
import PersonalCenter from '../views/PersonalCenter'
import BasicInformation from '../components/PersonalCenter/BasicInformation'
import MyPost from '../components/PersonalCenter/MyPost'
import MyCouplet from '../components/PersonalCenter/MyCouplet'
import GetCoupletDetailInfo
    from '../components/PersonalCenter/GetCoupletDetailInfo'
import ModificationPost from '../components/PersonalCenter/ModificationPost'
import MyCollection from '../components/PersonalCenter/MyCollection'
import MyFocus from '../components/PersonalCenter/MyFocus'
import MyFan from '../components/PersonalCenter/MyFan'
import MyBookshelf from '../components/PersonalCenter/MyBookshelf'
import DocumentDetail from '../components/PersonalCenter/DocumentDetail'
import SystemNotification from '../components/PersonalCenter/SystemNotification'
import RecentlyViewed from '../components/PersonalCenter/RecentlyViewed'
import MyManuscript from '../components/PersonalCenter/MyManuscript'
import PostPublish from '../components/PersonalCenter/PostPublish'
import ChineseCouplets from '../components/CoupletCorrelation/ChineseCouplets'
import MasterDetail from '../components/CoupletCorrelation/MasterDetail'
import CoupletDetails from '../components/CoupletCorrelation/CoupletDetails'
import PostDetails from '../components/CoupletCorrelation/PostDetails'
import CoupletsLibrary from '../components/CoupletsLibrary/CoupletsLibrary'
import HomePage from '../views/HomePage.vue'
import CoupletClass from '../components/Couplet_Class/Vedio'
import CompetitionChannel from "../views/CompetitionChannel";
import TEditor from "../components/TEditor/Editor";
import CompetitionDetail from "../components/CompetitionChannel/CompetitionDetail"

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {path: '/', redirect: '/home'},
        {path: '/login', component: Login, name: 'login'},
        {path: '/home', component: HomePage, name: 'HomePage'},
        {path: '/coupletClass', component: CoupletClass, name: 'CoupletClass'},
        {
            path: '/competitionChannel',
            component: CompetitionChannel,
            name: 'CompetitionChannel',
        },
        {path: '/editor', component: TEditor, name: 'TEditor'},
        {
            path: '/personalCenter',
            component: PersonalCenter,
            redirect: '/personalCenter/basicInformation',
            name: 'personalCenter',
            children: [
                {
                    path: 'basicInformation',
                    component: BasicInformation,
                    name: 'basicInformation'
                },
                {
                    path: 'myPost', component: MyPost, name: 'myPost'
                },
                {
                    path: 'myCouplet', component: MyCouplet, name: 'myCouplet'
                },
                {
                    path: 'modificationPost',
                    component: ModificationPost,
                    name: 'modificationPost'
                },
                {
                    path: 'getCoupletDetailInfo',
                    component: GetCoupletDetailInfo,
                    name: 'getCoupletDetailInfo'
                },
                {
                    path: 'myCollection', component: MyCollection, name: 'myCollection'
                },
                {
                    path: 'myFocus', component: MyFocus, name: 'myFocus'
                },
                {
                    path: 'myFan', component: MyFan, name: 'myFan'
                },
                {
                    path: 'systemNotification',
                    component: SystemNotification,
                    name: 'systemNotification'
                },
                {
                    path: 'myBookshelf', component: MyBookshelf, name: 'myBookshelf'
                },
                {
                    path: 'documentDetail',
                    component: DocumentDetail,
                    name: 'documentDetail'
                },
                {
                    path: 'recentlyViewed',
                    component: RecentlyViewed,
                    name: 'recentlyViewed'
                },
                {
                    path: 'myManuscript', component: MyManuscript, name: 'myManuscript'
                },
                {
                    path: 'postPublish', component: PostPublish, name: 'postPublish'
                }
            ]
        },
        {
            path: '/competitionDetail',
            component: CompetitionDetail,
            name: 'competitionDetail'
        },
        {
            path: '/chineseCouplets',
            component: ChineseCouplets,
            name: 'chineseCouplets'
        },
        {
            path: '/coupletDetails',
            component: CoupletDetails,
            name: 'coupletDetails'
        },
        {path: '/postDetails', component: PostDetails, name: 'postDetails'},
        {path: '/masterDetail', component: MasterDetail, name: 'masterDetail'},
        {
            path: '/coupletSearch',
            component: CoupletSearch,
            name: 'coupletSearch'
        },
        {
            path: '/coupletsLibrary',
            component: CoupletsLibrary,
            name: 'coupletsLibrary'
        },


    ]

})

export default router
