<template>

    <div>
        <div>
            <div style="width: 80%;margin-left: 1%" class="main">
                <el-card shadow="hover" v-for="(item,i) in fanList" :key="i"
                         style="margin-left: 5%;width:700px ">
                    <div  style="width: 100%;height: 80px;">
                        <div style="display: flex">
                            <div style="margin-left: 20px">
                                <el-avatar :size="40" :src="item.userPortrait"></el-avatar>
                            </div>
                            <div style="margin-left: 10px;text-align: left">
                                <span>{{item.userNickname}}</span><br/>
                            </div>
                        </div>
                        <div style="text-align:left;margin-left: 50px" class="introduce">
                            {{item.userIntroduction}}
                        </div>
                    </div>
                </el-card>
                <br/>
                <div class="footer" style="margin: 0 auto;width: 100%;">
                    <div class="block">
                        <el-pagination
                                background
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :page-size="pageSize"
                                layout="total, prev, pager, next, jumper"
                                :total=total>
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import {getFanList} from '../../apis/user'

    export default {
        data(){
            return{
                fanList:[],
                pageSize:5,
                pageNum:1,
                total:0,


            }
        },
        methods:{
            async getFanList(){
                const  temp=await getFanList({
                    userId:window.localStorage.getItem('userId'),
                    pageSize:this.pageSize,
                    pageNum:this.pageNum
                })
                this.fanList=temp.data.fanList
                this.total=temp.data.total
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.pageNum=val
                this.getFanList()
                console.log(`当前页: ${val}`);
            },

        },
        mounted() {
            this.getFanList()
        }
    }

</script>

<style scoped>
    .introduce{
        overflow:hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;

    }
    .my_button{
        color: #f56c6c;
        background: #fef0f0;
        border: #fbc4c4 solid;
        border-radius: 20px;
        padding: 12px 23px;
        text-align: center;
        font-size: 16px;
        -webkit-transform: scale(0.7);
    }

</style>