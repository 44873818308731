import request from './request'




export const getCoupletListByCoupletType = async ({ userId,coupletTypeId,pageSize,pageNum }) => {
  return await request.get('/couplet-server/couplet/getCoupletListByCoupletType', {
    params: {
      userId,
      coupletTypeId,
      pageSize,
      pageNum
    },
  })
}