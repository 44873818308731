import request from "./request";


export const getDocumentList = async ({ userId, pageSize,pageNum}) => {
    return await request.get('/file-server/document-collection/getDocumentList', {
        params: {
            userId,
            pageSize,
            pageNum
        },
    })
}
//根据资料id获取资料详情
export const getDocumentDetail = async ({ userId, documentId}) => {
    return await request.get('/file-server/document/getDocumentDetail', {
        params: {
            userId,
            documentId
        },
    })
}
//获取文件路径
export const readDocument = async ({  documentId}) => {
    return await request.get('/file-server/document/readDocument', {
        params: {
            documentId
        },
    })
}
//从我的书架移除
export const deleteDocumentByDocumentId = async ({ userId, documentId}) => {
    return await request.post('/file-server/document-collection/deleteDocumentByDocumentId', {
        data: {
            userId,
            documentId
        },
    })
}
//加入到我的书架
export const addDocument = async ({ userId, documentId}) => {
    return await request.post('/file-server/document-collection/addDocument', {
        data: {
            userId,
            documentId
        },
    })
}


//获取资料的评论列表
export const getDocumentComment = async ({  documentId,pageSize,pageNum}) => {
    return await request.get('/file-server/document-comment/getDocumentComment', {
        params: {
            documentId,
            pageSize,
            pageNum
        },
    })
}
//提交新的用户评论 不确定的写法
export const addDocumentComment = async ({ newDocumentComment}) => {
    return await request.post('/file-server/document-comment/addDocumentComment', {
        data: {
            newDocumentComment
        },
    })
}
//获取最近浏览的资料
export const getDocumentBrowseList = async ({ userId, pageSize,pageNum}) => {
    return await request.get('/file-server/document-browse/getDocumentBrowseList', {
        params: {
            userId,
            pageSize,
            pageNum
        },
    })
}
//根据资料id删除最近浏览的资料记录
export const deleteDocumentBrowse = async ({ userId,documentId}) => {
    return await request.post('/file-server/document-browse/deleteDocumentBrowse', {
        params: {
            userId,
            documentId
        },
    })
}