<template>
    <div>

        <el-form :model="post" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
        style="margin-left: -30px;margin-right: 40px"
        >

            <el-form-item label="作者" prop="name">
                <el-input v-model="post.postUserName"></el-input>
            </el-form-item>
            <el-form-item label="标题" prop="name">
                <el-input v-model="post.postTitle"></el-input>
            </el-form-item>
            <el-form-item label="类型" >
                <el-select v-model="post.postType" placeholder="请选择活动区域">
                    <el-option
                            v-for="item in postTypes"
                            :key="item.postTypeId"
                            :label="item.postTypeDetail"
                            :value="item.postTypeDetail">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="上联" prop="name">
                <el-input type="textarea" v-model="post.postFirstLine"></el-input>
            </el-form-item>
            <el-form-item label="下联" prop="name">
                <el-input type="textarea" v-model="post.postSecondLine"></el-input>
            </el-form-item>




            <el-form-item label="赏析" >
                <el-input type="textarea" v-model="post.postAppreaciation"></el-input>
            </el-form-item>
            <el-form-item label="注释" >
                <el-input type="textarea" v-model="post.postExplanation"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="modifyPost">提交修改</el-button>
                <el-button @click="goBack">返回</el-button>
            </el-form-item>
        </el-form>
<!--        评论区展示-->

        <div   class="my-reply" style="width: 100%">
            <div style="display: flex; ">
               <div style="width:600px;margin-left: 60px;margin-right: 20px;">
                   <el-input placeholder="快来发表你的评论吧！" v-model="textarea"></el-input>
               </div>
                <el-button  size="medium" type="primary" style="margin-left: 20px;" @click="doComment">发表评论</el-button>
            </div>
            <br/>
            <div>
                <div v-for="(item,i) in postCommentList" :key="i" style="width: 100%;height: 100px;" >
                    <div style="display: flex">
                      <div style="margin-left: 100px">
                          <el-avatar  :size="40" :src="item.userPortrait" ></el-avatar>
                      </div>
                        <div style="margin-left: 10px;text-align: left">
                            <span >{{item.userNickName}}</span><br/>
                            <span >{{item.postCommentTime}}</span>
                        </div>
                    </div>
                    <div style="text-align:left;margin-left: 150px">
                           {{item.postCommentContent}}
                    </div>
                </div>
                <br>
                <div class="footer" style="margin: 0 auto;width: 100%;">
                    <div class="block">
                        <el-pagination
                                background
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :page-size="pageSize"
                                layout="total, prev, pager, next, jumper"
                                :total=total>
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>



    </div>

</template>

<script >

    import {getPostTypes,getBasePostInfo,getPostCommentList,doComment,modifyPost} from "../../apis/post";
    export default {
        data(){
            return{
                postTypes:[],
                post: {
                    postId:'',
                    postTitle:'',
                    postUserName:'',
                    postFirstLine:'',
                    postSecondLine:'',
                    postAppreaciation:'',
                    postExplanation:'',
                    postType:'',
                },
                // rules: {
                //     name:  { required: true, message: '内容不能为空', trigger: 'blur' },
                //    },
                //评论相关参数
                textarea:'',
                postCommentList:[],
                pageNum:1,
                pageSize:5,
                total:0,
            }
        },
        methods:{
            goBack(){
                this.$router.go(-1);
            },
            async getPostTypes(){
                const temp=await getPostTypes();
                this.postTypes=temp.data.postType
            },
            async modifyPost(){
                const temp=await modifyPost({
                    postId:this.post.postId,
                    postTitle:this.post.postTitle,
                    postUserName:this.post.postUserName,
                    postFirstLine:this.post.postFirstLine,
                    postSecondLine:this.post.postSecondLine,
                    postAppreaciation:this.post.postAppreaciation,
                    postExplanation:this.post.postExplanation,
                    postType:this.post.postType,
                })
                console.log(temp)
            },
            async getBasePostInfo(){
                const temp=await  getBasePostInfo({postId:this.$route.query.postId});
                this.post=temp.data.formData
            },
            async doComment(){
                const temp=await  doComment({
                    postCommentPostId:this.$route.query.postId,
                    postCommentUserId:window.localStorage.getItem('userId'),
                    postCommentContent:this.textarea
                });
                this.getPostCommentList()
                console.log(temp)
            },
            async getPostCommentList(){
               const temp=await getPostCommentList({
                        postId:this.$route.query.postId,
                        pageNum:this.pageNum,
                        pageSize:this.pageSize
                    })
                this.postCommentList=temp.data.postCommentList
                this.total=temp.data.total
            },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.pageNum=val
            this.getPostCommentList()
        }
        },
        mounted() {
            this.getBasePostInfo()
            this.getPostTypes()
            this.getPostCommentList()
        }
    }

</script>


<style scoped>


</style>